import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import WebURLRecording from "./Components/WebURLRecording";

const WebURLDetails = () => {
  const location = useLocation();
  const lastSession = useUserStore((state) => state.lastSession);
  const [detailsTab, setDetailsTab] = useState("0");

  const webUrlDetails =
    useUserStore((state) => state.webUrlDetails) ||
    myLocalStorage.getItem("webUrlDetails");
  const latestTenant =
    lastSession.latestTenant ||
    myLocalStorage.getItem("latestTenant")?.tenantName;
  const locationState = location?.state || {};
  const { item } = locationState;

  return (
    <>
      <TabContext value={detailsTab}>
        <Box sx={{ position: "relative", padding: 2 }}>
          <Tabs
            value={detailsTab}
            onChange={(event, newValue) => setDetailsTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              icon={<RadioButtonCheckedOutlinedIcon />}
              label="Recordings"
              value="0"
            />
          </Tabs>
          <TabPanel value="0">
            <WebURLRecording
              urlDetails={webUrlDetails}
              tenantName={latestTenant}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};

export default WebURLDetails;
