import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  Autocomplete,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";

const K8sRequestResourceAccessForm = ({
  open,
  onClose,
  data,
  type,
  clusterName,
}) => {
  const [suggestedRoles, setSuggestedRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [step, setStep] = useState(1);
  const [requestMessage, setRequestMessage] = useState("");
  const [isCustomRole, setIsCustomRole] = useState(false);
  const [accessType, setAccessType] = useState("DYNAMIC");
  const [selectedTime, setSelectedTime] = useState("");
  const userData = useUserStore((state) => state.user);
  const tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [upn, setUpn] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [verbs, setVerbs] = useState([]);
  const [selectedVerbs, setSelectedVerbs] = useState(null);
  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const fetchSuggestedRoles = async () => {
    try {
      const payload = {
        wsTenantName: tenantName,
        clusterId: data.clusterId,
        cloudProviderType: data.cloudProviderType,
        type: type,
        resourceAccountId: data.cloudResourceAccountId,
        namespace: data.namespace,
      };
      const response = await axios.post(
        `${NGROK}/api/k8-resources/v1/suggestRoles/${data.name}`,
        payload,
      );
      setSuggestedRoles(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAzureUserConfiguration = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/azureUserConfigure/v1/get?tenantName=${tenantName}&email=${userData.email}`,
      );
      if (response?.data) {
        let { azureUserUpn, displayName } = response?.data;
        setUpn(azureUserUpn);
        setDisplayName(displayName);
      }
    } catch (e) {}
  };

  const fetchVerbs = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/k8-resources/v1/role-verbs`,
      );
      if (response?.data) {
        setVerbs(response?.data);
      }
    } catch (e) {
      setVerbs([]);
    }
  };

  useEffect(() => {
    fetchSuggestedRoles();
    fetchAzureUserConfiguration();
    fetchVerbs();
  }, []);

  const handleRoleSelect = (role) => {
    setSelectedRoles((prev) =>
      prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role],
    );
  };

  const handleSubmit = async () => {
    console.log(type);
    const payload = isCustomRole
      ? {
          verbs: selectedVerbs ? selectedVerbs : ["get"],
          resourcesName: data.name,
          k8ResourceType: type,
          k8ResourceId: data.uid,
          k8ResourceName: data.name,
          namespace: data.namespace,
          userName: upn,
          expiryTimeAmount: selectedTime,
          wsUserEmail: userData.email,
          message: requestMessage,
          clusterId: data.clusterId,
          cloudResourceAccountId: data.cloudResourceAccountId,
          cloudType: data.cloudProviderType,
          wsTenantName: tenantName,
          user: displayName,
          clusterName: clusterName,
        }
      : {
          roleId: selectedRoles[0]?.roleUid,
          roleName: selectedRoles[0]?.roleName,
          roleKind: selectedRoles[0]?.roleKind,
          resourcesName: data.name,
          k8ResourceType: type,
          k8ResourceId: data.uid,
          k8ResourceName: data.name,
          namespace: data.namespace,
          userName: upn,
          expiryTimeAmount: selectedTime,
          wsUserEmail: userData.email,
          message: requestMessage,
          clusterId: data.clusterId,
          cloudResourceAccountId: data.cloudResourceAccountId,
          cloudType: data.cloudProviderType,
          wsTenantName: tenantName,
          user: displayName,
          clusterName: clusterName,
        };

    await axios.post(`${NGROK}/api/k8-resources/v1/requests/raise`, payload);
    onClose();
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    if (value?.length > 3 || value.includes("e") || value === 0) return;

    if (regex.test(value)) {
      setSelectedTime(value);
    } else if (!value) setSelectedTime("");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {step === 1 ? "Select Roles" : "Confirm Access Request"}
      </DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <>
            <List>
              {suggestedRoles.length > 0 ? (
                suggestedRoles.map((role, index) => (
                  <ListItem key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRoles.includes(role)}
                          onChange={() => handleRoleSelect(role)}
                        />
                      }
                      label={role.roleName}
                    />
                    <ListItemSecondaryAction>
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <Typography>No roles available. Use custom role.</Typography>
              )}
            </List>
          </>
        ) : (
          <>
            <Stack spacing={2} mt={3}>
              <Typography variant="h6">Selected Roles:</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {isCustomRole ? (
                  <Chip label={"Custom Role"} variant="outlined" />
                ) : (
                  selectedRoles.map((roles, index) => (
                    <Chip
                      key={index}
                      label={roles.roleName}
                      variant="outlined"
                    />
                  ))
                )}
              </Stack>
            </Stack>
            {isCustomRole ? (
              <Stack spacing={2} mt={3} mb={3}>
                <Typography variant="h6">Select Verbs:</Typography>

                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  value={selectedVerbs || []}
                  options={verbs}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Verbs" />
                  )}
                  onChange={(e, value) => setSelectedVerbs(value)}
                />
              </Stack>
            ) : null}

            <FormControl component="fieldset">
              <Typography variant="h6">Access Type:</Typography>
              <RadioGroup
                aria-label="access-type"
                name="access-type"
                value={accessType}
                onChange={(e) => handleAccessType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="PERMANENT"
                  control={<Radio />}
                  label="Permanent"
                />
                <FormControlLabel
                  value="DYNAMIC"
                  control={<Radio />}
                  label="Timed"
                />
              </RadioGroup>
            </FormControl>

            <Typography variant="h6">Resource Name: {data.name}</Typography>

            <Stack spacing={2} mt={3}>
              <Typography variant="h6">Access justification:</Typography>
              <TextField
                maxRows={5}
                minRows={5}
                inputProps={{ maxLength: 150 }}
                multiline
                value={requestMessage}
                onChange={(e) => setRequestMessage(e.target.value)}
              />
            </Stack>

            {accessType === "DYNAMIC" && (
              <Stack spacing={2}>
                <Typography variant="h6">Time:</Typography>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]+",
                  }}
                  value={selectedTime}
                  type="text"
                  fullWidth
                  size="small"
                  label={"Enter time in minutes"}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {step === 1 ? (
          <>
            <Button
              onClick={() => setStep(2)}
              disabled={!isCustomRole && selectedRoles.length === 0}
            >
              Next
            </Button>
            <Button
              disabled={selectedRoles.length !== 0}
              onClick={() => {
                setSelectedRoles([]);
                setIsCustomRole(true);
                setStep(2);
              }}
            >
              Custom Role
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => setStep(1)}>Back</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default K8sRequestResourceAccessForm;
