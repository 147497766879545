import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { awsIcons } from "../../AWS/AWSIcons";
import { myLocalStorage } from "../../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const AzureUserGroups = ({ user, groups }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { role, userId } = useUserStore((state) => state.user);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const deleteTheGroupFromUser = async (group) => {
    try {
      const response = await axios.delete(
        `${NGROK}/api/azure/v1/v1/groupMembership?userId=${user?.azureUserId}&groupId=${group?.azureId}&tenantName=${latestTenant}`,
      );
    } catch (e) {}
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClickRequestForm = (group) => {
    console.log(group);
    setSelectedGroup(group);
  };
  const filteredgroups = groups?.filter((data) => data != null) || [];
  if (loading) return <CircularProgress />;

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Box display="flex" alignItems="center">
                    {awsIcons.groups}
                    <Box ml={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        Groups
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredgroups?.length > 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Groups"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </TableCell>
                  </TableRow>
                  {filteredgroups.map((group, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={role === "TENANT_USER" ? 1 : 2}>
                        <Stack
                          spacing={2}
                          display="flex"
                          alignItems="center"
                          direction={"row"}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {awsIcons.group}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {group?.displayName}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            deleteTheGroupFromUser(group);
                          }}
                        >
                          Remove membership
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      No Groups Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AzureUserGroups;
