import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  Chip,
  FormControl,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AWSRequestResourceAccessForm = ({
  data,
  from,
  open,
  onClose,
  title,
  user,
  arn,
}) => {
  const userData = useUserStore((state) => state.user);
  const [publishedPolicies, setPublishedPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [step, setStep] = useState(1);
  const [requestMessage, setRequestMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [accessType, setAccessType] = useState("DYNAMIC");
  const [searchQuery, setSearchQuery] = useState(""); // For search filter

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const getStringBeforeAtSymbol = (str) => {
    const atIndex = str.indexOf("@");
    return atIndex !== -1 ? str.substring(0, atIndex) : str;
  };

  const handleInputChange = (value) => {
    const regex = /^(?!0)[0-9\b]+$/;
    const stringValue = String(value);

    const valueAsNumber = parseInt(value);

    if (
      stringValue?.length > 3 ||
      stringValue.includes("e") ||
      stringValue === "0"
    )
      return;

    if (regex.test(stringValue) || !stringValue) {
      setSelectedTime(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-") {
      event.preventDefault();
    }
  };

  const handleAccessType = (value) => {
    if (value === "PERMANENT") setSelectedTime("");
    setAccessType(value);
  };

  const handleClose = () => {
    setRequestMessage("");
    setSelectedTime("");
    setSelectedPolicies([]);
    onClose();
  };

  const handleSubmit = async () => {
    const username = getStringBeforeAtSymbol(userData.email);

    const requestdata = {
      userId: userData?.userId,
      username: username,
      awsUsername: user?.username,
      accessType: from,
      accessARN: arn,
      accessName: title,
      requestMessage,
      requestStatus: "PENDING",
      privilegeLevel: accessType,
      selectedTime: selectedTime,
      createdAt: new Date().toISOString(),
      tenantName: latestTenant,
      selectedPolicies: selectedPolicies,
    };

    try {
      await axios.post(`${NGROK}/api/aws-request-access`, requestdata);
      handleClose();
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  const fetchAllPublishedResources = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/aws/getAllPublishedPolicies?tenantName=${latestTenant}`,
      );
      if (response?.data) {
        setPublishedPolicies(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePolicySelect = (policy) => {
    if (selectedPolicies.includes(policy)) {
      setSelectedPolicies(selectedPolicies.filter((p) => p !== policy));
    } else {
      setSelectedPolicies([...selectedPolicies, policy]);
    }
  };

  const filteredPolicies = publishedPolicies.filter((policy) =>
    policy.policyName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (from === "console") setStep(2);
    fetchAllPublishedResources();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ fontSize: "18px" }}>
        <strong>
          {step === 1 ? "Select Policies" : `Access request to ${title}`}
        </strong>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4} mt={2}>
          {step === 1 ? (
            <>
              <TextField
                label="Search Policies"
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <List
                sx={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  marginTop: "16px",
                }}
              >
                {filteredPolicies.length === 0 ? (
                  <>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h6" align="center">
                        <strong>No policies available</strong>
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        color="textSecondary"
                      >
                        Publish policies to list
                      </Typography>
                    </ListItem>
                  </>
                ) : (
                  filteredPolicies.map((policy) => (
                    <ListItem key={policy.policyArn}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPolicies.includes(policy)}
                            onChange={() => handlePolicySelect(policy)}
                          />
                        }
                        label={policy.policyName}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ) : (
            <>
              {from !== "console" ? (
                <Stack spacing={2} mt={3}>
                  <Typography variant="h6">Selected Policies:</Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {selectedPolicies.map((policy, index) => (
                      <Chip
                        key={index}
                        label={policy.policyName}
                        variant="outlined"
                      />
                    ))}
                  </Stack>
                </Stack>
              ) : null}

              <FormControl component="fieldset">
                <Typography variant="h6">Access Type:</Typography>
                <RadioGroup
                  aria-label="access-type"
                  name="access-type"
                  value={accessType}
                  onChange={(e) => handleAccessType(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="PERMANENT"
                    control={<Radio />}
                    label="Permanent"
                  />
                  <FormControlLabel
                    value="DYNAMIC"
                    control={<Radio />}
                    label="Timed"
                  />
                </RadioGroup>
              </FormControl>

              <Typography variant="h6">Resource Name: {title}</Typography>

              <Stack spacing={2} mt={3}>
                <Typography variant="h6">Access justification:</Typography>
                <TextField
                  maxRows={5}
                  minRows={5}
                  inputProps={{ maxLength: 150 }}
                  multiline
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                />
              </Stack>

              {accessType === "DYNAMIC" && (
                <Stack spacing={2}>
                  <Typography variant="h6">Time:</Typography>
                  <TextField
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]+",
                    }}
                    onKeyPress={handleKeyPress}
                    value={selectedTime}
                    type="text"
                    fullWidth
                    size="small"
                    label={"Enter time in minutes"}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      console.log(newValue);
                      handleInputChange(newValue);
                    }}
                    error={
                      from === "console" &&
                      selectedTime &&
                      parseInt(selectedTime) < 15
                    }
                    helperText={
                      from === "console" &&
                      selectedTime &&
                      parseInt(selectedTime) < 15
                        ? "Minimum time duration is 15 minutes"
                        : ""
                    }
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {step === 1 ? (
          <Button
            onClick={() => setStep(2)}
            color="primary"
            disabled={selectedPolicies.length === 0}
          >
            Next
          </Button>
        ) : (
          <>
            {from !== "console" ? (
              <Button onClick={() => setStep(1)} color="secondary">
                Back
              </Button>
            ) : null}

            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={
                from === "console"
                  ? !selectedTime || parseInt(selectedTime) < 15
                  : false
              }
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AWSRequestResourceAccessForm;
