import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Paper,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Tabs,
  Tab,
  Grid,
  Switch,
  Autocomplete,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { myLocalStorage } from "../../../components/StorageHelper";
import { awsIcons } from "./AWSIcons";
import useUserStore from "../../../services/userStore";

const Wrapper = styled.div`
  max-height: 500px;
`;
const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const useStylesScroll = makeStyles({
  root: {
    maxHeight: 750,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});

const AwsRoles = () => {
  const classes = useStyles();
  const classes1 = useStylesScroll();

  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [modalPermission, setModalPermission] = useState(null);
  const [filter, setFilter] = useState("all");
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRolesDetails, setShowRolesDetails] = useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [filteredPermission, setFilteredPermission] = useState([]);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [filteredusers, setFilteredUsers] = useState([]);
  const [filteredgroups, setFilteredGroups] = useState([]);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);

  const userData = useUserStore((state) => state.user);
  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };

  const handlePermissionSearch = (event) => {
    const search = event.target.value;
    const filteredPermission = consolidatedPermissions.filter(
      (permission) =>
        permission?.PolicyName &&
        permission?.PolicyName.toLowerCase().includes(search.toLowerCase()),
    );
    console.log(filteredPermission);
    setFilteredPermission(filteredPermission);
  };

  const handleRoleSearchChange = (event) => {
    setSearch(event.target.value);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
  };

  const getTheSpecificPolicyDocument = async (policyArn) => {
    const data = `policyArn=${encodeURIComponent(policyArn)}`;

    try {
      const response = await axios.get(
        `${NGROK}/api/aws/getSpecificIAMPolicydetails?tenantName=${latestTenant}&policyarn=${encodeURIComponent(policyArn)}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
  const handlePermissionClick = (permission) => {
    setSelectedPermission(permission.policyARN);
    const policyArn = permission.policyARN;

    console.log(permission, policyArn, users);

    const groupsWithPolicy = users
      .flatMap((user) => user.groups || [])
      .filter((group) =>
        group.groupPolicies?.some((policy) => policy.policyARN === policyArn),
      );
    const usersWithPolicy = users.filter((user) => {
      const hasPolicyInGroups = user.groups?.some((group) =>
        group.groupPolicies?.some((policy) => policy.policyARN === policyArn),
      );
      const hasPolicyInUserPolicies = user.userPolicies?.some(
        (policy) => policy.policyARN === policyArn,
      );
      return hasPolicyInGroups || hasPolicyInUserPolicies;
    });

    setFilteredUsers(usersWithPolicy);
    setFilteredGroups(groupsWithPolicy);
  };

  const handlePolicyInfoOpen = async (permission) => {
    setIsPermissionLoading(true);
    const permissionDoc = await getTheSpecificPolicyDocument(
      permission.policyARN,
    );

    if (permissionDoc) {
      const policyDocumentString = permissionDoc.policyDocument;
      const parsedPolicyDocument = JSON.parse(policyDocumentString);
      permissionDoc.policyDocument = parsedPolicyDocument;
      console.log(permissionDoc);
      setModalPermission(permissionDoc);
      setShowRolesDetails(true);
    } else {
      setShowRolesDetails(false);
    }
    setIsPermissionLoading(false);
  };

  const handlePolicyInfoClose = () => {
    setModalPermission(null);
  };

  const handleFilterChange = (event, newFilter) => {
    const filteredRoles = filterRoles(roles, newFilter);
    setFilter(newFilter);
    setSelectedRole(filteredRoles.length > 0 ? filteredRoles[0] : null);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
    setSelectedPermission(null);
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.post(
      `${NGROK}/api/aws/ondemand?tenantName=${latestTenant}`,
    );
    fetchData();
    setIsSyncing(false);
  };

  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamusers?tenantName=${latestTenant}`,
    );

    if (response.status === 200) return response.data;
    else return [];
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamroles?tenantName=${latestTenant}`,
    );
    if (response.status === 200) return response.data;
    else return [];
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [users, roles] = await Promise.all([getAllUsers(), getAllRoles()]);
      setUsers(users);
      setRoles(roles);
      setLoading(false);
      if (initialLoad) {
        const filteredRoles = filterRoles(roles, filter);
        setSelectedRole(filteredRoles.length > 0 ? filteredRoles[0] : null);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [latestTenant]);

  const filterRoles = (roles, filter) => {
    if (filter === "default") {
      return roles?.filter(
        (role) =>
          role.roleName.startsWith("AWSServiceRoleFor") &&
          role.roleName !== "Whiteswan-Role",
      );
    } else if (filter === "custom") {
      return roles?.filter(
        (role) =>
          !role?.roleName.startsWith("AWSServiceRoleFor") &&
          role?.roleName !== "Whiteswan-Role",
      );
    }
    return roles?.filter((role) => role.roleName !== "Whiteswan-Role");
  };

  const filteredRoles = filterRoles(
    roles?.filter(
      (role) =>
        role?.roleName &&
        role?.roleName.toLowerCase().includes(search.toLowerCase()),
    ),
    filter,
  );

  const consolidatedPermissions = selectedRole
    ? [...selectedRole.rolePolicies]
    : [];

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const updatePermissionARN = async (arn) => {
    const updatedPermissions = filteredPermission.map((permission) => {
      if (permission.policyARN === arn) {
        return {
          ...permission,
          isManagedAttachedPolicies: !permission.isManagedAttachedPolicies,
        };
      }
      return permission;
    });

    try {
      const response = await axios.put(
        `${NGROK}/api/aws/publishPolicies?tenantName=${latestTenant}&policyARN=${arn}`,
      );
      fetchData();
    } catch (err) {
      console.err(err);
    }
    setFilteredPermission(updatedPermissions);
  };

  useEffect(() => {
    if (selectedRole) {
      setFilteredPermission(consolidatedPermissions);
    }
  }, [selectedRole]);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          IAM Roles
        </Typography>
        <Box
          display="flex"
          mt={5}
          mb={5}
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {tenantList?.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              ) : null}
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: "auto" }}
              disabled={isSyncing}
              onClick={ondemandButton}
            >
              {isSyncing ? "Syncing" : "Sync"}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack spacing={5} mt={2}>
              <Box display="flex" justifyContent="space-between">
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="filter toggle buttons"
                >
                  <ToggleButton
                    value="all"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="default"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Default
                  </ToggleButton>
                  <ToggleButton
                    value="custom"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Custom
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Stack direction="row" spacing={5}>
                <Box width="30%">
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflow: "auto",
                      height: "100%",
                    }}
                    className={classes1.root}
                  >
                    <Table
                      sx={{
                        height: "fit-content",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        },
                        "& td, & th": {
                          borderBottom: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                      size="large"
                      aria-label="sticky table"
                      className={classes1.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Roles Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            position: "sticky",
                            top: "54px",
                            backgroundColor: "#f5f5f5",
                            zIndex: 1,
                          }}
                        >
                          <TableCell>
                            <TextField
                              label="Search Roles"
                              variant="outlined"
                              value={search}
                              onChange={handleRoleSearchChange}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        {filteredRoles.length > 0 ? (
                          filteredRoles
                            .filter(
                              (name) => name.roleName !== "Whiteswan-Role",
                            )
                            .map((role) => (
                              <TableRow
                                key={role.arn}
                                onClick={() => handleRoleClick(role)}
                                selected={role.arn === selectedRole?.arn}
                                sx={{
                                  "&:hover": {
                                    background: "#f5f5f5",
                                    cursor: "pointer",
                                  },
                                  "& td, & th": {
                                    borderBottom: "1px solid #233044",
                                    fontSize: "18px",
                                  },
                                  "&.Mui-selected": {
                                    background: "#233044 !important",
                                    "& td, & th": {
                                      color: "#fff",
                                    },
                                  },
                                }}
                              >
                                <TableCell>
                                  <Box display="flex" alignItems="center">
                                    {awsIcons.roles}
                                    <Box ml={2}>
                                      <Typography variant="h6">
                                        {role.roleName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant="h6">
                                No Roles Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box width="35%">
                  {filteredRoles.length > 0 ? (
                    selectedRole ? (
                      <Stack spacing={5}>
                        {filteredPermission.length > 0 ? (
                          <TableContainer>
                            <Table
                              sx={{
                                height: "fit-content",
                                "& th": {
                                  background: "#233044",
                                  color: "#fff",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "18px",
                                },
                              }}
                              size="large"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    Permission Name
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow colSpan={2}>
                                  <TableCell colSpan={2}>
                                    <TextField
                                      label="Search permissions"
                                      variant="outlined"
                                      onChange={handlePermissionSearch}
                                      fullWidth
                                    />
                                  </TableCell>
                                </TableRow>
                                {filteredPermission.length > 0 ? (
                                  filteredPermission.map(
                                    (permission, index) => (
                                      <TableRow
                                        selected={
                                          selectedPermission ===
                                          permission?.policyARN
                                        }
                                        key={permission?.policyARN}
                                        onClick={() => {
                                          handlePermissionClick(permission);
                                          handlePolicyInfoOpen(permission);
                                        }}
                                        sx={{
                                          "&:hover": {
                                            background: "#f5f5f5",
                                            cursor: "pointer",
                                          },
                                          "& td, & th": {
                                            border: "1px solid #233044",
                                            fontSize: "18px",
                                          },
                                          "&.Mui-selected": {
                                            background: "#233044 !important",
                                            "& td, & th": {
                                              color: "#fff",
                                            },
                                          },
                                        }}
                                      >
                                        <TableCell>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            {awsIcons.policy}
                                            <Box ml={2}>
                                              <Typography variant="h6">
                                                <Stack>
                                                  <Typography variant="h5">
                                                    {permission?.policyName}
                                                  </Typography>
                                                  <Typography variant="body1">
                                                    {permission?.policyARN}
                                                  </Typography>
                                                </Stack>
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Switch
                                              checked={
                                                permission.isManagedAttachedPolicies
                                              }
                                              onChange={(e) => {
                                                e.preventDefault();
                                                updatePermissionARN(
                                                  permission?.policyARN,
                                                );
                                              }}
                                            />
                                            <Typography variant="body2">
                                              Publish
                                            </Typography>{" "}
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    ),
                                  )
                                ) : (
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h5">
                                        No permissions found for this role.
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Typography variant="h5">
                            No permissions found for this role.
                          </Typography>
                        )}
                      </Stack>
                    ) : (
                      <Typography variant="body2">
                        Select a role to view permissions.
                      </Typography>
                    )
                  ) : null}
                </Box>
                <Box width="35%">
                  {isPermissionLoading ? (
                    <CircularProgress />
                  ) : (
                    selectedRole &&
                    showRolesDetails && (
                      <Box
                        sx={{
                          border: "1px solid",
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            background: "#233044",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "18px",
                            p: 4.8,
                          }}
                        >
                          Role Details
                        </Typography>
                        <>
                          <Tabs
                            value={selectedDetailsTab}
                            onChange={handleDetailsTabChange}
                          >
                            <Tab
                              icon={<PersonIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Users ({filteredusers.length})
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<GroupsIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Groups ({filteredgroups.length})
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<SecurityIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Permissions
                                </Typography>
                              }
                            />
                          </Tabs>
                          {selectedDetailsTab === 0 && (
                            <List>
                              {filteredusers.map((user, index) => (
                                <ListItem key={index}>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {user.username}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {user.arn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          )}
                          {selectedDetailsTab === 1 && (
                            <List>
                              {filteredgroups.map((group) => (
                                <ListItem key={group.groupId}>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {group.groupName}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {group.arn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          )}
                          {selectedDetailsTab === 2 && (
                            <Box
                              mt={5}
                              sx={{
                                margin: "10px",
                                padding: "10px",
                              }}
                            >
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Attached Permissions:</strong>
                              </Typography>

                              <List>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>
                                          Policy Name:{" "}
                                          {modalPermission.policyName}
                                        </strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {modalPermission.policyArn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Policy Document:</strong>
                              </Typography>
                              <Wrapper className={classes.root}>
                                {modalPermission?.policyDocument ? (
                                  <Paper
                                    elevation={3}
                                    style={{ padding: "10px", margin: "10px" }}
                                  >
                                    <Typography sx={{ fontSize: 18 }}>
                                      <strong>Version:</strong>{" "}
                                      {modalPermission.policyDocument.Version}
                                    </Typography>

                                    {modalPermission.policyDocument.Statement.map(
                                      (statement, index) => (
                                        <Grid
                                          container
                                          key={index}
                                          spacing={2}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="body2"
                                              sx={{ fontSize: 18 }}
                                            >
                                              <strong>
                                                Statement {index + 1}:
                                              </strong>
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            {statement?.Sid && (
                                              <Typography sx={{ fontSize: 18 }}>
                                                <strong>Sid:</strong>{" "}
                                                {statement.Sid}
                                              </Typography>
                                            )}

                                            <Typography sx={{ fontSize: 18 }}>
                                              <strong>Effect:</strong>{" "}
                                              {statement.Effect}
                                            </Typography>
                                            <Typography sx={{ fontSize: 18 }}>
                                              <strong>Action:</strong>{" "}
                                              <List disablePadding>
                                                {Array.isArray(
                                                  statement?.Action,
                                                ) ? (
                                                  statement.Action?.map(
                                                    (action, index) => (
                                                      <ListItem
                                                        key={index}
                                                        disableGutters
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <ListItemText>
                                                          <Typography
                                                            sx={{
                                                              fontSize: 18,
                                                            }}
                                                          >
                                                            {action}
                                                          </Typography>
                                                        </ListItemText>
                                                      </ListItem>
                                                    ),
                                                  )
                                                ) : (
                                                  <Typography
                                                    sx={{ fontSize: 18 }}
                                                  >
                                                    {statement.Action}
                                                  </Typography>
                                                )}
                                              </List>
                                            </Typography>
                                            <Typography sx={{ fontSize: 18 }}>
                                              <strong>Resource:</strong>{" "}
                                              {Array.isArray(
                                                statement.Resource,
                                              ) ? (
                                                <List>
                                                  {statement.Resource?.map(
                                                    (resource, index) => (
                                                      <ListItem
                                                        key={index}
                                                        disableGutters
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <ListItemText>
                                                          <Typography
                                                            sx={{
                                                              fontSize: 18,
                                                            }}
                                                          >
                                                            {resource}
                                                          </Typography>
                                                        </ListItemText>
                                                      </ListItem>
                                                    ),
                                                  )}
                                                </List>
                                              ) : (
                                                <Typography
                                                  sx={{ fontSize: 18 }}
                                                >
                                                  {statement.Resource}
                                                </Typography>
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ),
                                    )}
                                  </Paper>
                                ) : (
                                  <Typography sx={{ fontSize: 18 }}>
                                    No policy document available
                                  </Typography>
                                )}
                              </Wrapper>
                            </Box>
                          )}
                        </>
                      </Box>
                    )
                  )}
                </Box>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default AwsRoles;
