import React, { useEffect, useState } from "react";
import { myLocalStorage } from "../../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AccessVMResources from "./AccessVMResources";
import AccessStorages from "./AccessStorages";
import AccessDBInstances from "./AccessDBInstances";
import { SiKubernetes } from "react-icons/si";
import AccessCluster from "./AccessCluster";

const azureResources = [
  {
    id: "VM",
    name: "Virtual Machines",
    icon: azureIcons.vm,
    description:
      "Azure Virtual Machines provide on-demand, scalable computing resources with a range of operating systems and configurations to choose from.",
  },
  {
    id: "STRGS",
    name: "Storages",
    icon: azureIcons.strgs,
    description:
      "Azure Storage offers secure, massively scalable cloud storage for data objects, files, and applications, accessible via various protocols.",
  },
  {
    id: "DB",
    name: "Databases",
    icon: azureIcons.database,
    description:
      "Azure Databases include a wide variety of database services, such as SQL Database, Cosmos DB, and Database for MySQL, to support different data requirements.",
  },
  {
    id: "K8",
    name: "Kubernetes",
    icon: (
      <SiKubernetes
        style={{
          width: "1.3em",
          height: "1.3em",
        }}
      />
    ),
    description:
      "Kubernetes is an open-source container orchestration platform that automates the deployment, scaling, and management of containerized applications. It provides features like self-healing, load balancing, and automated rollouts for efficient application management.",
  },
];

const AccessAzureResources = ({ user, groups }) => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResource, setSelectedResource] = useState(null);
  const [publishedVMInstances, setPublishedVMInstances] = useState([]);
  const [publishedStorages, setPublishedStorages] = useState([]);
  const [publishedDBInstances, setPublishedDBInstances] = useState([]);
  const [publishedK8Clusters, setPublishedK8Clusters] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;
  const filteredAzureResources = azureResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchPublishedInstances = async (isFirst = false, type) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/azureResources/v1/publish?tenantName=${tenantName}&type=${type}`,
      );
      switch (type) {
        case "VIRTUAL_MACHINE":
          setPublishedVMInstances(response?.data);
          break;
        case "STORAGE_ACCOUNT":
          setPublishedStorages(response?.data);
          break;
        case "DATABASE":
          setPublishedDBInstances(response?.data);
          break;
        case "AZURE_KUBERNETES":
          setPublishedK8Clusters(response?.data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching published instances:", error);
    }
    setLoading(false);
  };

  const fetchResourceDetails = async (resource) => {
    setSelectedResource(resource);
    switch (resource?.id) {
      case "VM":
        fetchPublishedInstances(true, "VIRTUAL_MACHINE");
        break;
      case "STRGS":
        fetchPublishedInstances(true, "STORAGE_ACCOUNT");
        break;
      case "DB":
        fetchPublishedInstances(true, "DATABASE");
        break;
      case "K8":
        fetchPublishedInstances(true, "AZURE_KUBERNETES");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (azureResources.length > 0) {
      setSelectedResource(azureResources[0]);
    }
  }, []);

  useEffect(() => {
    fetchResourceDetails(selectedResource);
  }, [tenantName]);

  return (
    <Stack direction={"row"} spacing={5}>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {awsIcons.resources}
                    <Box ml={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        Resource Details
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={1}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Resources"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {filteredAzureResources.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={1} align="center">
                    <Typography>No resources found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredAzureResources.map((resource) => (
                  <TableRow
                    key={resource.id}
                    selected={resource === selectedResource}
                    onClick={() => fetchResourceDetails(resource)}
                    sx={{
                      "&:hover": {
                        background: "#f5f5f5",
                        cursor: "pointer",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "18px",
                      },
                      "&.Mui-selected": {
                        background: "#233044 !important",
                        "& td, & th": {
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    <TableCell>
                      <Stack
                        direction={"row"}
                        spacing={5}
                        display="flex"
                        alignItems="center"
                      >
                        <div>{resource.icon}</div>
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {resource.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: "350px",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {resource.description}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {selectedResource ? (
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {selectedResource?.id === "VM" ? (
                <AccessVMResources
                  publishedVMInstances={publishedVMInstances}
                  setPublishedVMInstances={setPublishedVMInstances}
                  user={user}
                />
              ) : null}
              {selectedResource?.id === "STRGS" ? (
                <AccessStorages
                  publishedStorages={publishedStorages}
                  setPublishedStorages={setPublishedStorages}
                  user={user}
                />
              ) : null}
              {selectedResource?.id === "DB" ? (
                <AccessDBInstances
                  publishedDBInstances={publishedDBInstances}
                  setPublishedDBInstances={setPublishedDBInstances}
                  user={user}
                />
              ) : null}
              {selectedResource?.id === "K8" ? (
                <AccessCluster
                  publishedK8Clusters={publishedK8Clusters}
                  setPublishedK8Clusters={setPublishedK8Clusters}
                  user={user}
                />
              ) : null}
            </>
          )}
        </Box>
      ) : null}
    </Stack>
  );
};

export default AccessAzureResources;
