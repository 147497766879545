import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ListAllAuthUserModal = ({ auth, loginToUrl }) => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    setOpen(false);
    setSelectedUser(null);
  }, []);

  const calltoLogintUser = () => {
    setOpen(false);
    loginToUrl(selectedUser);
  };

  const handleCheckboxChange = (user) => {
    setSelectedUser(selectedUser?.email === user?.email ? null : user);
  };

  return (
    <>
      <Box gap={2}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          Connect
        </Button>
        <Dialog open={open} fullWidth>
          <DialogTitle>WebUrl Auth</DialogTitle>
          <DialogContent>
            <List
              sx={{
                maxHeight: "450px",
                overflowY: "auto",
                marginTop: "16px",
              }}
            >
              {auth.auth.map((user, index) => (
                <ListItem key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUser?.email === user.email}
                        onChange={() => handleCheckboxChange(user)}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: 15,
                        }}
                      >
                        {user.email}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              color="error"
            >
              <Typography
                sx={{
                  fontSize: 15,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              disabled={!selectedUser}
              variant="outlined"
              onClick={calltoLogintUser}
            >
              <Typography
                sx={{
                  fontSize: 15,
                }}
              >
                Submit
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ListAllAuthUserModal;
