import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import AzureGenericRoles from "./Components/AzureGenericRoles";
import { CustomTabPanel } from "../../../GenericMethods";
import { TabContext, TabList } from "@mui/lab";
import K8Roles from "./Components/K8Roles";
import { SiKubernetes } from "react-icons/si";
import { awsIcons } from "../AWS/AWSIcons";

const AzureRoles = () => {
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [tabValue, setTabValue] = useState("Roles");

  const userData = useUserStore((state) => state.user);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: { tenantName: selectedTenantName },
    });
    setIsSyncing(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1) {
      setSelectedTenantName(tenantList[0].tenantName);
    } else if (tenantList.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;
      const tenant = tenantList.find((t) => t.tenantName === latestTenantName);
      setSelectedTenantName(
        tenant ? tenant.tenantName : tenantList[0].tenantName,
      );
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ fontSize: "22px" }}>
        Azure Roles
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={5}
        mb={5}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenantName || " "}
          options={tenantList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => handleTenantChange(value)}
        />
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <TabContext value={tabValue}>
            <TabList onChange={handleChange}>
              <Tab label="Roles" value="Roles" icon={awsIcons.roles} />
              <Tab label="K8 Roles" value="K8" icon={<SiKubernetes />} />
            </TabList>
          </TabContext>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          disabled={isSyncing}
          onClick={ondemandButton}
        >
          {isSyncing ? "Syncing" : "Sync"}
        </Button>
      </Box>
      <CustomTabPanel value={tabValue} index="Roles">
        <AzureGenericRoles />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index="K8">
        <K8Roles
          selectedTenantName={selectedTenantName}
          allapi={`/api/azureResources/v1/all?type=AZURE_KUBERNETES&tenantName=${selectedTenantName}`}
          rolesApi="/api/k8-resources/v1/roles/byClusterId"
          rolespoliciesApi="/api/k8-resources/v1/rolePolicies/byRoleUid"
          cloud="AZURE"
        />
      </CustomTabPanel>
    </Box>
  );
};

export default AzureRoles;
