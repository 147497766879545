import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { tableStyles, useMaxStyles } from "../../../../GenericMethods";
import K8sRequestResourceAccessForm from "./K8sRequestResourceAccessForm";
import useNotificationsStore from "../../../../services/notificationStore";
import userK8Notifications from "../../../../services/notificationStore";
import TimerForAWS from "../../../components/TimerForAWS";

const K8PublishedResources = ({
  selectedType,
  cloudType,
  clusterId,
  clusterName,
}) => {
  const [allResources, setAllResources] = useState([]);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const classes = useMaxStyles();
  const [k8ResourceModal, setK8ResourceModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const { userK8Notifications } = useNotificationsStore();
  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const fetchAllResources = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/k8-resources/v1/publish?type=${selectedType}&tenantName=${latestTenant}&cloudType=${cloudType}&clusterId=${clusterId}`,
      );
      if (response?.data) {
        setAllResources(response?.data);
      }
    } catch (e) {}
  };

  const handleClickRequestForm = (resource) => {
    setK8ResourceModal(true);
    setSelectedResource(resource);
  };

  const fetchResourceLevel = userK8Notifications?.filter(
    (userAzureNotification) =>
      userAzureNotification.clusterName === clusterName &&
      userAzureNotification.status === "APPROVED",
  );
  const checkAccessTiming = (name) => {
    return fetchResourceLevel.find((data) => data.k8ResourceName === name);
  };

  const filteredResources = allResources
    ? allResources.filter((resource) =>
        resource?.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  useEffect(() => {
    fetchAllResources();
  }, [selectedType]);
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", height: "100%" }}
        className={classes.root}
      >
        <Table
          sx={tableStyles}
          size="large"
          aria-label="sticky table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} align="left">
                Resources
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allResources.length > 0 ? (
              <>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: "54px",
                    backgroundColor: "#f5f5f5",
                    zIndex: 1,
                  }}
                >
                  <TableCell colSpan={7}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Resources"
                      fullWidth
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </TableCell>
                </TableRow>
                {filteredResources.length > 0 ? (
                  filteredResources.map((resourceDetails, index) => {
                    const accessData = checkAccessTiming(resourceDetails?.name);

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {resourceDetails.name}
                              </Typography>
                            </Box>
                            <Box>
                              {accessData ? (
                                <TimerForAWS
                                  accessTimeOverAt={accessData.validTo}
                                  resourceId={resourceDetails?.clusterId}
                                  selectedTime={accessData.expiryTimeAmount}
                                  setTimedResourcesWithoutAccess={
                                    setTimedResourcesWithoutAccess
                                  }
                                />
                              ) : (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleClickRequestForm(resourceDetails)
                                  }
                                >
                                  Request Access
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4">No Resource found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography align="center">
                    No Resource details Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {k8ResourceModal && selectedResource ? (
        <K8sRequestResourceAccessForm
          open={k8ResourceModal}
          onClose={() => setK8ResourceModal(false)}
          data={selectedResource}
          clusterId={clusterId}
          type={selectedType}
          clusterName={clusterName}
        />
      ) : null}
    </>
  );
};

export default K8PublishedResources;
