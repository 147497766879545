import {
  Alert,
  Box,
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";

const AzureSetup = ({ tenantName }) => {
  const userData = useUserStore((state) => state.user);
  const [clientId, setClientId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [errors, setErrors] = useState({});
  const [config, setConfig] = useState(null);
  const [configerror, setConfigerror] = useState(null);
  const [unconfigSnack, setUnconfigSnack] = useState(false);
  const [syncstatus, setSyncStatus] = useState(false);

  const handleUnconfigureSubmit = async () => {
    try {
      const response = await axios.delete(
        `${NGROK}/api/azure/v1/tenant?tenantName=${tenantName}`,
      );
      setUnconfigSnack(true);
      fetchAzureConfig(false);
    } catch (e) {
      setUnconfigSnack(false);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!clientId) newErrors.clientId = "Client ID is required";
    if (!tenantId) newErrors.tenantId = "Tenant ID is required";
    if (!clientSecret) newErrors.clientSecret = "Client Secret is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        const response = await axios.post(`${NGROK}/api/azure/auth/configure`, {
          client_id: clientId,
          az_tenant_id: tenantId,
          client_secret: clientSecret,
          ws_tenant_name: tenantName,
          ws_tenant_email: userData?.email,
          subscription_id: subscriptionId,
        });
        if (response.status === 201) {
          fetchAzureConfig();
        }
      } catch (error) {
        setConfigerror(
          error.response?.data
            ? error?.response?.data?.message
            : "Error configuring Azure settings",
        );
      }
    }
  };

  const fetchAzureConfig = async (intervalCalls = true) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/azure/auth/configuration`,
        {
          params: {
            tenantName: tenantName,
          },
        },
      );
      if (response.data) {
        setConfig(response?.data);
        setClientId(response.data.clientId);
        setTenantId(response.data.tenantId);
        setSubscriptionId(response.data.subscriptionId);
        setClientSecret(response.data.clientSecret);
        setSyncStatus(response.data.syncStatus);
      }
    } catch (e) {
      if (!intervalCalls) {
        setConfig("");
        setClientId("");
        setTenantId("");
        setSubscriptionId("");
        setClientSecret("");
      }
    }
  };

  useEffect(() => {
    fetchAzureConfig(false);
    const interval = setInterval(() => {
      fetchAzureConfig(true);
    }, [10000]);
    return () => clearInterval(interval);
  }, [userData.email]);

  return (
    <>
      <Container maxWidth="md">
        <Snackbar
          open={unconfigSnack}
          autoHideDuration={6000}
          onClose={() => setUnconfigSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setUnconfigSnack(false)}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            Unconfigured Azure successfully
          </Alert>
        </Snackbar>
        {configerror ? (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setConfigerror(null)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={() => setConfigerror(false)}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {configerror}
            </Alert>
          </Snackbar>
        ) : null}
        {config ? (
          <Alert sx={{ marginTop: 1 }} severity="success">
            {"Azure account configured successfully"}
          </Alert>
        ) : null}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Azure Initial Setup
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Client Id"
                variant="outlined"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                error={!!errors.clientId}
                helperText={errors.clientId}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Tenant Id"
                variant="outlined"
                value={tenantId}
                onChange={(e) => setTenantId(e.target.value)}
                error={!!errors.tenantId}
                helperText={errors.tenantId}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Client Secret"
                variant="outlined"
                type="password"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                error={!!errors.clientSecret}
                helperText={errors.clientSecret}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Subscription ID (Optional)"
                variant="outlined"
                value={subscriptionId}
                onChange={(e) => setSubscriptionId(e.target.value)}
              />
            </Box>
            <Stack direction={"row"} spacing={5}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={config ? true : false}
              >
                Submit Configuration
              </Button>
              {config ? (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mt: 2 }}
                    onClick={handleUnconfigureSubmit}
                    disabled={syncstatus}
                  >
                    {syncstatus}
                    Unconfigure Azure Account
                  </Button>
                </>
              ) : null}
            </Stack>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AzureSetup;
