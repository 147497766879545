import React, { useEffect, useState } from "react";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import GCPVMInstances from "./GCPVMInstances";
import GCPStorages from "./GCPStorages";
import GCPDatabase from "./GCPDatabase";

const gcpResources = [
  {
    id: "VM",
    name: "Virtual Machines",
    description:
      "Google Cloud Virtual Machines (VMs) are scalable, flexible compute resources that can be customized to run applications, manage workloads, and handle data processing.",
    icon: azureIcons.vmLarge,
  },
  {
    id: "STRGS",
    name: "Storages",
    description:
      "Google Cloud Storage offers scalable and secure object storage for storing and accessing your data, whether it's a small amount or a massive scale.",
    icon: azureIcons.strgs,
  },
  {
    id: "DB",
    name: "Databases",
    description:
      "Google Cloud Databases provide managed database services that are secure, reliable, and optimized for high performance and scalability.",
    icon: awsIcons.rdsLarge,
  },
];

const GCPInfraResources = ({ selectedTenantName, projectId }) => {
  const [selectedResource, setSelectedResource] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchInstanceFortheResource = async (resource) => {
    setSelectedResource(resource);
  };
  const filteredGcpResources = gcpResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (gcpResources.length > 0) {
      setSelectedResource(gcpResources[0]);
      fetchInstanceFortheResource(gcpResources[0]);
    }
  }, []);

  return (
    <>
      <Box width="30%">
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell>Resource Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={1}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Resources"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                  />
                </TableCell>
              </TableRow>

              {filteredGcpResources.map((resource) => (
                <TableRow
                  key={resource.name}
                  selected={resource === selectedResource}
                  onClick={() => fetchInstanceFortheResource(resource)}
                  sx={{
                    "&:hover": {
                      background: "#f5f5f5",
                      cursor: "pointer",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "8px",
                    },
                    "&.Mui-selected": {
                      background: "#233044 !important",
                      "& td, & th": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <TableCell>
                    <Stack
                      direction={"row"}
                      spacing={5}
                      display="flex"
                      alignItems="center"
                    >
                      <div>{resource.icon}</div>

                      <Box ml={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          {resource.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: "350px",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {resource.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box width="70%">
        {selectedResource?.id === "VM" && (
          <GCPVMInstances
            tenantName={selectedTenantName}
            projectId={projectId}
          />
        )}
        {selectedResource?.id === "STRGS" && (
          <GCPStorages tenantName={selectedTenantName} projectId={projectId} />
        )}
        {selectedResource?.id === "DB" && (
          <GCPDatabase tenantName={selectedTenantName} projectId={projectId} />
        )}
      </Box>
    </>
  );
};

export default GCPInfraResources;
