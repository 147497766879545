import React from "react";
import async from "./components/Async";

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Guards

// Auth components

import Page404 from "./pages/auth/Page404";

// Page components

import Pricing from "./pages/pages/Pricing";
import Projects from "./pages/pages/Projects";
import AccessManagement from "./pages/pages/AccessManagement";
import GetStart from "./pages/pages/GetStart";

// Documentation

// Protected routes
import Endpoints from "./pages/pages/Endpoints/Endpoints";
import ProfileResources from "./pages/pages/ProfileResources/ProfileResources";
import Account from "./pages/pages/Account";
import TenantManagement from "./pages/pages/TenantManagement/TenantManagement";
import Downloads from "./pages/pages/Downloads";
import EndpointsResources from "./pages/pages/EndpointsResources/EndpointsResources";
import Profiles from "./pages/pages/Profiles/Profiles";
import SessionsAndProcesses from "./pages/pages/SessionsAndProcesses";
import ResourceRequests from "./pages/pages/ResourceRequests/ResourceRequests";
import ResourceRequestsUser from "./pages/pages/ResourceRequests/ResourceRequestsUser";
import ServerAuditLogs from "./pages/pages/logs/ServerAuditLogs";
import AuditLogs from "./pages/pages/logs/AuditLogs";
import DeviceDebugLogs from "./pages/pages/logs/DeviceDebugLogs";
import ServerDebugLogs from "./pages/pages/logs/ServerDebugLogs";
import OverProvisioning from "./pages/pages/reports/OverProvisioning";
import PrivilegeCreep from "./pages/pages/reports/HistoricalData/PrivilegeCreep";
import LocalUsers from "./pages/pages/reports/HistoricalData/LocalUsers";
import SuspiciousAccounts from "./pages/pages/reports/HistoricalData/SuspiciousAccounts";
import Categories from "./pages/pages/Profiles/Categories/Categories";
import ResourceCategories from "./pages/pages/ResourceCategories/ResourceCategories";
import About from "./pages/pages/About";
import Connections from "./pages/pages/Connections";
import CustomSignIn from "./pages/pages/auth/CustomSignIn";
import CustomSignUp from "./pages/pages/auth/CustomSignUp";
import PrivateRoute from "./components/auth/PrivateRoute";
import TestPage from "./pages/pages/TestPage";
import ServiceTerms from "./pages/pages/ServiceTerms";
import Loader from "./components/Loader";
import TenantSettings from "./pages/pages/TenantSettings/TenantSettings";
import Default from "./pages/dashboards/Default";
import Alerts from "./pages/dashboards/Alerts";

import DevieSpecificLogs from "./pages/pages/logs/DevieSpecificLogs";
import EndpointsGroups from "./pages/pages/EndpointsGroups/EndpointsGroups";
import Credentials from "./pages/pages/TenantManagement/Credentials";
import UserMembership from "./pages/pages/UserMembership/UserMembership";
import OrganizationUnit from "./pages/pages/OrganizationUnit/OrganizationUnit";
import OuComputes from "./pages/pages/OuComputes/OuComputes";
import LOLBin from "./pages/dashboards/LOLBin";
import UserInvites from "./pages/pages/UserInvites";
import ActiveDirectoryUsers from "./pages/pages/ActiveDirectoryUsers";
import AWSLayout from "./layouts/AWSLayout";
import AwsRoles from "./pages/pages/AWS/AwsRoles";
import AWSUsers from "./pages/pages/AWS/AWSUsers";
import AWSResources from "./pages/pages/AWS/AWSResources";
import AWSResourceRequest from "./pages/pages/AWS/AWSResourceRequest";
import AWSUserDetails from "./pages/pages/AWS/AWSUserDetails";
import AWSGroups from "./pages/pages/AWS/AWSGroups";
import EndpointsDeviceDetails from "./pages/pages/Endpoints/components/EndpointsDeviceDetails";
import UserRoles from "./pages/pages/UserRoles/UserRoles";
import ProfileSettings from "./pages/pages/Profiles/components/ProfileSettings";
import AzureGroups from "./pages/pages/Azure/AzureGroups";
import AzureUsers from "./pages/pages/Azure/AzureUsers";
import AzureDevices from "./pages/pages/Azure/AzureDevices";
import TenantAccessManagement from "./pages/pages/TenantManagement/TenantAccessManagement";
import AzureResources from "./pages/pages/Azure/AzureResources";
import AzureUserDetails from "./pages/pages/Azure/Components/AzureUserDetails";
import AzureUserInfo from "./pages/pages/Azure/Components/AzureUserInfo";
import AzureRoles from "./pages/pages/Azure/AzureRoles";
import GcpGroups from "./pages/pages/GCP/GcpGroups";
import GcpUsers from "./pages/pages/GCP/GcpUsers";
import GcpResources from "./pages/pages/GCP/GcpResources";
import GcpRoles from "./pages/pages/GCP/GcpRoles";
import GcpUserInfo from "./pages/pages/GCP/GcpUserInfo";
import ADAnalytics from "./pages/pages/ADAnalytics";
import { GraphProvider } from "./pages/pages/ADAnalytics/GraphContext";
import WebUrls from "./pages/pages/WebUrls";
import WebURLDetails from "./pages/pages/WebUrls/WebURLDetails";
import VideoPlayer from "./pages/pages/WebUrls/Components/VideoPlayer";
import AccessKubernetes from "./pages/pages/Azure/Components/AccessKubernetes";

// Dashboard components
// const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));

// Icon components
const Tasks = async(() => import("./pages/pages/Tasks"));
const admin = "TENANT_ADMIN";
const sysAdmin = "SYS_ADMIN";
const user = "TENANT_USER";

const routes = [
  {
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },
  {
    path: "tenantDetails",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "",
        element: <TenantManagement />,
      },
    ],
  },
  {
    path: "userInvites",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<UserInvites />} />}
      />
    ),
  },
  {
    path: "adUsers",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<ActiveDirectoryUsers />} />}
      />
    ),
  },
  /*   {
    path: "registeredUsers",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<RegisteredUsers />} />}
      />
    ),
  }, */
  {
    path: "registeredUsers",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<UserRoles />} />}
      />
    ),
  },
  {
    path: "tenantSettings",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<TenantSettings />} />}
      />
    ),
  },
  {
    path: "credentials",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<Credentials />} />}
      />
    ),
  },
  {
    path: "api-access",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<TenantAccessManagement />} />}
      />
    ),
  },
  {
    path: "endpointGroups",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "",
        element: <EndpointsGroups />,
      },
    ],
  },
  {
    path: "webUrls",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <WebUrls />,
      },
      {
        path: "webUrldetails",
        element: <WebURLDetails />,
      },
    ],
  },
  {
    path: "endpoints",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Endpoints />,
      },
      {
        path: "sessions",
        element: <SessionsAndProcesses />,
      },
      {
        path: "resources",
        element: <EndpointsResources />,
      },
      {
        path: "connections",
        element: <Connections />,
      },
      {
        path: "deviceAuditLogs",
        element: <DevieSpecificLogs />,
      },
      {
        path: "deviceDetails",
        element: <EndpointsDeviceDetails />,
      },
    ],
  },
  {
    path: "video-player",

    children: [
      {
        path: "",
        element: <VideoPlayer />,
      },
    ],
  },
  {
    path: "loading",
    element: <Loader />,
  },
  {
    path: "terms_of_service",
    element: <ServiceTerms />,
  },
  {
    path: "/",
    element: <CustomSignIn />,
  },
  {
    path: "signIn",
    element: <CustomSignIn />,
  },
  {
    path: "signUp",
    element: <CustomSignUp />,
  },

  {
    path: "dashboard",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "overview",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "lolbin",
        element: <LOLBin />,
      },
    ],
  },
  {
    path: "policyProfiles",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),

    children: [
      {
        path: "",
        element: <Profiles />,
      },
      {
        path: "resources",
        element: <ProfileResources />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "profileSettings",
        element: <ProfileSettings />,
      },
    ],
  },
  {
    path: "pricing",
    element: <DashboardLayout children={<Pricing />} />,
  },
  {
    path: "management",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AccessManagement />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },

  {
    path: "resourceRequests",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<ResourceRequests />} />}
      />
    ),
  },
  {
    path: "resourceCategories",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<ResourceCategories />} />}
      />
    ),
  },
  {
    path: "userResourceRequests",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout children={<ResourceRequestsUser />} />}
      />
    ),
  },

  {
    path: "serverAuditLogs",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<ServerAuditLogs />} />}
      />
    ),
  },
  {
    path: "auditLogs",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<AuditLogs />} />}
      />
    ),
  },
  {
    path: "deviceDebugLogs",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<DeviceDebugLogs />} />}
      />
    ),
  },
  {
    path: "serverDebugLogs",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin]}
        element={<DashboardLayout children={<ServerDebugLogs />} />}
      />
    ),
  },

  {
    path: "aws",
    element: (
      <PrivateRoute access={[admin, sysAdmin, user]} element={<AWSLayout />} />
    ),
    children: [
      {
        path: "users",
        element: <AWSUsers />,
      },
      {
        path: "roles",
        element: <AwsRoles />,
      },
      {
        path: "groups",
        element: <AWSGroups />,
      },
      {
        path: "resources",
        element: <AWSResources />,
      },
      {
        path: "resourcerequest",
        element: <AWSResourceRequest />,
      },
      {
        path: "users/userDetails",
        element: <AWSUserDetails />,
      },
    ],
  },

  {
    path: "activeDirectory",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "organizationUnit",
        element: <OrganizationUnit />,
      },
      {
        path: "userMembership",
        element: <UserMembership />,
      },
      {
        path: "computers",
        element: <OuComputes />,
      },
      {
        path: "analytics",
        element: (
          <GraphProvider>
            <ADAnalytics />
          </GraphProvider>
        ),
      },
    ],
  },
  {
    path: "azure-directory",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "devices",
        element: <AzureDevices />,
      },
      {
        path: "groups",
        element: <AzureGroups />,
      },
      {
        path: "azureusers",
        element: <AzureUsers />,
      },
      {
        path: "resources",
        element: <AzureResources />,
      },
      {
        path: "roles",
        element: <AzureRoles />,
      },
      {
        path: "azureusers/userDetails",
        element: <AzureUserInfo />,
      },
      {
        path: "kubernetes",
        element: <AccessKubernetes />,
      },
    ],
  },
  {
    path: "gcp",
    element: (
      <PrivateRoute access={[admin, sysAdmin, user]} element={<AWSLayout />} />
    ),
    children: [
      {
        path: "groups",
        element: <GcpGroups />,
      },
      {
        path: "gcpusers",
        element: <GcpUsers />,
      },
      {
        path: "resources",
        element: <GcpResources />,
      },
      {
        path: "roles",
        element: <GcpRoles />,
      },
      {
        path: "gcpusers/userDetails",
        element: <GcpUserInfo />,
      },
    ],
  },
  {
    path: "reports",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "overProvisioning",
        element: <OverProvisioning />,
      },
      {
        path: "privilegeCreep",
        element: <PrivilegeCreep />,
      },
    ],
  },

  {
    path: "localUsers",
    element: <DashboardLayout children={<LocalUsers />} />,
  },
  {
    path: "suspiciousAccounts",
    element: <DashboardLayout children={<SuspiciousAccounts />} />,
  },

  {
    path: "account",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout children={<Account />} />}
      />
    ),
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "downloads",
    element: (
      <PrivateRoute
        access={[admin, sysAdmin, user]}
        element={<DashboardLayout children={<Downloads />} />}
      />
    ),
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "about",
        element: <About />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "test",
        element: <TestPage />,
      },
    ],
  },

  {
    path: "/",
    element: (
      <PrivateRoute access={[admin, sysAdmin]} element={<DashboardLayout />} />
    ),
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },

  {
    path: "*",
    element: <Page404 />,
  },

  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
