import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useNotificationsStore from "../../../../services/notificationStore";
import {
  rowStyles,
  tableStyles,
  useMaxStyles,
} from "../../../../GenericMethods";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import K8PublishedResources from "./K8PublishedResources";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AccessKubernetes = () => {
  const { userAzureNotifications } = useNotificationsStore();
  const classes = useMaxStyles();
  const [publishedTypes, setPublishedTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [clusters, setClusters] = useState([]);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [search, setSearch] = useState("");
  const [searchTypes, setSearchTypes] = useState("");

  const fetchClusterDetails = async () => {
    try {
      const fetchResourceLevel = userAzureNotifications?.filter(
        (userAzureNotification) =>
          userAzureNotification.scopeType === "AZURE_KUBERNETES" &&
          userAzureNotification.status === "APPROVED",
      );
      const clusterIds = [
        ...new Set(fetchResourceLevel.map((item) => item.clusterId)),
      ];

      // Execute all API calls
      const responses = await axios.get(
        `${NGROK}/api/azureResources/v1/publish?tenantName=${latestTenant}&type=AZURE_KUBERNETES`,
      );

      const clusterDetails = responses.data.filter((item) =>
        clusterIds.includes(item.clusterId),
      );

      setClusters(clusterDetails);
    } catch (error) {
      console.error("Error fetching cluster details:", error);
      setClusters([]);
    }
  };

  const fetchAllPublishedTypes = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/k8-resources/v1/publish/types`,
      );
      if (response?.data) {
        setPublishedTypes(response?.data);
      }
    } catch (e) {}
  };

  const filteredClusters = clusters
    ? clusters.filter((cluster) =>
        cluster.name.toLowerCase().includes(search.toLowerCase()),
      )
    : [];
  const filteredTypes = publishedTypes
    ? publishedTypes.filter((type) =>
        type?.value.toLowerCase().includes(searchTypes.toLowerCase()),
      )
    : [];

  useEffect(() => {
    fetchAllPublishedTypes();
  }, [latestTenant]);

  useEffect(() => {
    fetchClusterDetails();
  }, [userAzureNotifications, latestTenant]);

  if (clusters.length === 0)
    return (
      <>
        <Box>
          <Typography variant="h4">No Cluster access available</Typography>
        </Box>
      </>
    );
  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box>
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", height: "100%" }}
            className={classes.root}
          >
            <Table
              sx={tableStyles}
              size="large"
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Clusters</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1}>
                    <TextField
                      variant="outlined"
                      value={search}
                      placeholder="Search Types"
                      fullWidth
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                {filteredClusters.length > 0 ? (
                  filteredClusters.map((cluster, index) => {
                    return (
                      <TableRow
                        key={index}
                        selected={cluster.azureId === selectedCluster?.azureId}
                        onClick={() => setSelectedCluster(cluster)}
                        sx={rowStyles}
                      >
                        <TableCell>
                          <Typography variant="h6" sx={{ fontSize: "18px" }}>
                            {cluster?.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4">No Cluster found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedCluster ? (
          <>
            <Box
              sx={{
                width: "35%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ overflow: "auto", height: "100%" }}
                className={classes.root}
              >
                <Table
                  sx={tableStyles}
                  size="large"
                  aria-label="sticky table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Types</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={1}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Types"
                          fullWidth
                          value={searchTypes}
                          onChange={(event) =>
                            setSearchTypes(event.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                    {filteredTypes.length > 0 ? (
                      filteredTypes.map((publishedType, index) => {
                        return (
                          <TableRow
                            key={index}
                            selected={publishedType.key === selectedType?.key}
                            onClick={() => setSelectedType(publishedType)}
                            sx={rowStyles}
                          >
                            <TableCell>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "18px" }}
                              >
                                {publishedType?.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h4">No Types found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                width: "35%",
              }}
            >
              {selectedType ? (
                <K8PublishedResources
                  selectedType={selectedType?.key}
                  cloudType={"AZURE"}
                  clusterId={selectedCluster?.azureId}
                  clusterName={selectedCluster?.name}
                />
              ) : null}
            </Box>
          </>
        ) : null}
      </Stack>
    </>
  );
};

export default AccessKubernetes;
