import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { useDropzone } from "react-dropzone";

const GCPSetup = ({ tenantName }) => {
  const userData = useUserStore((state) => state.user);

  const [showForm, setShowForm] = useState(false);
  const [config, setConfig] = useState([]);
  const [configerror, setConfigerror] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [privateKeyId, setPrivateKeyId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({});

  const fetchGCPConfig = async () => {
    try {
      const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
        params: { tenantName },
      });
      if (response.data?.length) {
        setConfig(response.data);
        setSelectedProject(response.data[0]);
        let {
          clientEmail,
          clientId,
          privateKey,
          privateKeyId,
          projectId,
          type,
        } = response.data[0];
        setClientId(clientId);
        setClientEmail(clientEmail);
        setPrivateKey(privateKey);
        setPrivateKeyId(privateKeyId);
        setProjectId(projectId);
        setType(type);
        setShowForm(false);
        setConfigerror(null);
      } else {
        setConfig([]);
        setConfigerror("No configurations found");
      }
    } catch (e) {
      console.error(e);
      setConfigerror("Failed to fetch configurations");
    }
  };

  const filteredConfig = config.filter((item) =>
    item.projectId.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const handleSelectProject = (project) => {
    let { clientEmail, clientId, privateKey, privateKeyId, projectId, type } =
      project;
    setSelectedProject(project);
    setClientId(clientId);
    setClientEmail(clientEmail);
    setPrivateKey(privateKey);
    setPrivateKeyId(privateKeyId);
    setProjectId(projectId);
    setType(type);
    setShowForm(false);
  };

  const handleUnconfigureSubmit = async (projectId) => {
    try {
      await axios
        .get(
          `${NGROK}/api/gcp/auth/delete-configuration?tenantName=${tenantName}&projectId=${projectId}`,
        )
        .then(() => {
          fetchGCPConfig();
        });
    } catch (e) {
      console.error(e);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".json",
    onDrop: (files) => handleFileUpload(files),
  });

  const handleFileUpload = (files) => {
    if (files.length === 1) {
      const file = files[0];

      const formData = new FormData();

      formData.append("file", file);

      axios
        .post(
          `${NGROK}/api/gcp/auth/upload-configuration?tenantName=${tenantName}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        )
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
          setClientId("");
          setClientEmail("");
          setPrivateKey("");
          setPrivateKeyId("");
          setProjectId("");
          setType("");
          fetchGCPConfig();
        })
        .catch((error) => {
          console.error(
            "Error uploading file:",
            error.response?.data || error.message,
          );
        });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!clientId) newErrors.clientId = "Client ID is required";
    if (!clientEmail) newErrors.clientEmail = "Client Email is required";
    if (!privateKey) newErrors.privateKey = "Private Key is required";
    if (!privateKeyId) newErrors.privateKeyId = "Private Key ID is required";
    if (!projectId) newErrors.projectId = "Project ID is required";
    if (!type) newErrors.type = "Credential Type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        let privateString = privateKey.replace(/\\n/g, "\n");
        const form = new FormData();
        form.append("client_id", clientId);
        form.append("client_email", clientEmail);
        form.append("private_key", privateString);
        form.append("private_key_id", privateKeyId);
        form.append("project_id", projectId);
        form.append("tenantName", tenantName);
        form.append("type", type);
        const formDataObject = {};
        form.forEach((value, key) => {
          formDataObject[key] = value;
        });
        const jsonString = JSON.stringify(formDataObject, null, 2);
        const jsonBlob = new Blob([jsonString], { type: "application/json" });
        const formDataWithJsonFile = new FormData();
        formDataWithJsonFile.append("file", jsonBlob, "form-data.json");
        axios
          .post(
            `${NGROK}/api/gcp/auth/upload-configuration?tenantName=${tenantName}`,
            formDataWithJsonFile,
            { headers: { "Content-Type": "multipart/form-data" } },
          )
          .then((response) => {
            console.log("File uploaded successfully:", response.data);
            fetchGCPConfig();
          })
          .catch((error) => {
            console.error(
              "Error uploading file:",
              error.response?.data || error.message,
            );
          });
      } catch (error) {
        console.error(error);
        setConfigerror("Error configuring GCP settings");
      }
    }
  };

  useEffect(() => {
    fetchGCPConfig();
  }, [userData.email]);

  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h4">GCP Setup</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowForm(true);
            setSelectedProject(null);
            setClientId("");
            setClientEmail("");
            setPrivateKey("");
            setPrivateKeyId("");
            setProjectId("");
            setType("");
          }}
        >
          Add Configuration
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Search Project"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            margin="normal"
          />
          <Paper>
            <List>
              {filteredConfig.map((item) => (
                <ListItem
                  sx={{
                    cursor: "pointer",
                  }}
                  key={item.projectId}
                  onClick={() => handleSelectProject(item)}
                  selected={selectedProject?.projectId === item.projectId}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="error"
                      onClick={() => handleUnconfigureSubmit(item.projectId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`Project ID: ${item.projectId}`}
                    secondary={`Client ID: ${item.clientId}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

        <Grid item xs={7}>
          {selectedProject ? (
            <Typography variant="h4">Project details</Typography>
          ) : null}
          <Box sx={{ mt: 4 }}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Client Id"
                  variant="outlined"
                  value={clientId}
                  disabled={!showForm}
                  onChange={(e) => setClientId(e.target.value)}
                  error={!!errors.clientId}
                  helperText={errors.clientId}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Client Email"
                  variant="outlined"
                  value={clientEmail}
                  disabled={!showForm}
                  onChange={(e) => setClientEmail(e.target.value)}
                  error={!!errors.clientEmail}
                  helperText={errors.clientEmail}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Private Key"
                  variant="outlined"
                  type="password"
                  value={privateKey}
                  disabled={!showForm}
                  onChange={(e) => setPrivateKey(e.target.value)}
                  error={!!errors.privateKey}
                  helperText={errors.privateKey}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Private Key ID"
                  variant="outlined"
                  value={privateKeyId}
                  disabled={!showForm}
                  onChange={(e) => setPrivateKeyId(e.target.value)}
                  error={!!errors.privateKeyId}
                  helperText={errors.privateKeyId}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Project ID"
                  variant="outlined"
                  value={projectId}
                  disabled={!showForm}
                  onChange={(e) => setProjectId(e.target.value)}
                  error={!!errors.projectId}
                  helperText={errors.projectId}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Credentials Type"
                  variant="outlined"
                  value={type}
                  disabled={!showForm}
                  onChange={(e) => setType(e.target.value)}
                  error={!!errors.type}
                  helperText={errors.type}
                />
              </Box>
              {showForm ? (
                <Stack direction={"row"} spacing={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2 }}
                  >
                    Submit Configuration
                  </Button>
                </Stack>
              ) : null}
            </form>
            {showForm ? (
              <>
                <Box sx={{ my: 4, textAlign: "center" }}>
                  <Typography variant="h6" color="textSecondary">
                    OR
                  </Typography>
                </Box>
                <Box
                  {...getRootProps()}
                  sx={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography variant="body1">
                    Drag & Drop your GCP JSON file here or click to select
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
          {!selectedProject && !showForm && (
            <Typography>Select project for details or create new</Typography>
          )}
        </Grid>
      </Grid>

      {configerror && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setConfigerror(null)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="error" variant="filled">
            {configerror}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default GCPSetup;
