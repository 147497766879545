import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { makeStyles } from "@mui/styles";
import { useMaxStyles } from "../../../../GenericMethods";

const useStyles = makeStyles({
  root: {
    maxHeight: 550,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});
const GCPDatabase = ({ tenantName, projectId }) => {
  const [loading, setLoading] = useState(false);
  const [dbs, setDbs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDBId, setSelectedDBId] = useState("");
  tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;
  const classes = useMaxStyles();

  const handlePublishToggle = async (event, id, projectId) => {
    const isManagedDatabase = event.target.checked;
    setDbs((prevData) =>
      prevData.map((vm) => (vm.id === id ? { ...vm, isManagedDatabase } : vm)),
    );

    const response = await axios.put(
      `${NGROK}/api/gcp/resource/publishDatabase?tenantName=${tenantName}&projectId=${projectId}&databaseId=${id}`,
    );
  };

  const selectedDb = (dbId) => {
    setSelectedDBId(dbId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchDbDatas = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/gcp/databases?tenantName=${tenantName}&projectId=${projectId}`,
      );
      if (response?.data) {
        setDbs(response?.data);
      }
    } catch (e) {
      setDbs([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (tenantName != null && projectId != null) fetchDbDatas(true, tenantName);
  }, [tenantName, projectId]);

  const filtereddbDatas = dbs?.filter((db) =>
    db?.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = dbs.filter((db) => db.id === selectedDBId);

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer
            component={Paper}
            sx={{
              overflow: "auto",
              height: "100%",
            }}
            className={classes.root}
          >
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                "& td, & th": {
                  borderBottom: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Databases
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : dbs.length > 0 ? (
                  <>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "54px",
                        backgroundColor: "#f5f5f5",
                        zIndex: 1,
                      }}
                    >
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Databases"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filtereddbDatas.map((dbData, index) => {
                      return (
                        <TableRow
                          key={index}
                          selected={dbData.id === selectedDBId}
                          onClick={() => {
                            selectedDb(dbData.id);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              borderBottom: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "18px",
                                  }}
                                >
                                  {dbData.name}
                                </Typography>
                              </Box>
                              <Box ml={2}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={dbData.isManagedDatabase}
                                      onChange={(e) =>
                                        handlePublishToggle(
                                          e,
                                          dbData.id,
                                          dbData.projectId,
                                        )
                                      }
                                      name="publishSwitch"
                                    />
                                  }
                                  label="Publish"
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No Databases Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedDBId && filteredData ? (
          <>
            <Box
              width={"50%"}
              sx={{
                border: "1px solid",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background: "#233044",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                  fontSize: "18px",
                  p: 4.8,
                }}
              >
                Details of {filteredData[0]?.name}
              </Typography>
              <List>
                {filteredData.length > 0 ? (
                  filteredData.map((db, index) => {
                    //const dbSize = getFormattedSize(db.maxSizeBytes);

                    return (
                      <div key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Database Name</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.name || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Instance Type</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.instanceType || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>State</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.state || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Region</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.region || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Created At</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {moment(new Date()).format(
                                  "YYYY-MM-DD HH:mm",
                                ) || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </div>
                    );
                  })
                ) : (
                  <Typography variant="body1"> No details available</Typography>
                )}
              </List>
            </Box>
          </>
        ) : null}
      </Stack>
    </>
  );
};

export default GCPDatabase;
