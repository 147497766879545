import {
  Box,
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const PublishPopover = ({ device, updateManageComputer, userRoleList }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState(
    device?.publishedroleIds ? device.publishedroleIds : [],
  );

  const handlePublishClick = (event, roleIds) => {
    setAnchorEl(event.currentTarget);
    setCheckedItems(roleIds ? roleIds : []);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCheckedItems([]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };

  const handleSubmit = () => {
    const filteredCheckedItems = checkedItems.filter(
      (item) => item !== undefined,
    );
    updateManageComputer(device.id, filteredCheckedItems);
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? `popover-${device.id}` : undefined;

  return (
    <>
      <Box gap={2}>
        <Button
          variant="outlined"
          onClick={(e) => handlePublishClick(e, device?.publishedroleIds)}
        >
          Publish
        </Button>
        {device.isManageComputer && device?.publishedroleIds?.length > 0 ? (
          <Typography>
            <strong>Roles: </strong>
            {device?.publishedroleIds
              ?.map((roleId) => {
                const role = userRoleList.find((roleData) => {
                  return roleData.roleId.toString() === roleId.toString();
                });
                return role ? role.roleName : roleId;
              })
              .slice(0, 1)
              .join(", ")}
            {device.publishedroleIds.length > 1 && (
              <Tooltip
                title={device?.publishedroleIds
                  ?.slice(1)
                  .map((roleId) => {
                    const role = userRoleList.find((roleData) => {
                      return roleData.roleId.toString() === roleId.toString();
                    });
                    return role ? role.roleName : roleId;
                  })
                  .join(", ")}
              >
                <span style={{ cursor: "pointer", color: "blue" }}>
                  {" "}
                  +{device.publishedroleIds.length - 1} more
                </span>
              </Tooltip>
            )}
          </Typography>
        ) : null}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            border: "0.25px solid #5A5A5AFF",
            borderRadius: 2,
            padding: 2,
          },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">
            <strong>Publish {device?.dNSHostName} to Roles</strong>
          </Typography>
          <Divider />
          <List sx={{ padding: "1px", borderRadius: 1 }}>
            {userRoleList
              .filter(
                (data) =>
                  data.roleName !== "epam-user" &&
                  data.roleName !== "super-user",
              )
              .map((role) => (
                <ListItem
                  key={role.roleId}
                  dense
                  button
                  onClick={handleToggle(role.roleId.toString())}
                >
                  <Checkbox
                    edge="start"
                    checked={checkedItems.includes(role.roleId.toString())}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={role.roleName} />
                </ListItem>
              ))}
          </List>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{ marginTop: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default PublishPopover;
