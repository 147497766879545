import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Tab,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { TabContext, TabList } from "@mui/lab";
import { SiKubernetes } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import K8Resources from "../Azure/Components/K8Resources";
import { CustomTabPanel } from "../../../GenericMethods";
import GCPInfraResources from "./Components/GCPInfraResources";

const GcpResources = () => {
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [tabValue, setTabValue] = useState("Resources");
  const [projectIdList, setProjectIdList] = useState([]);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [projectId, setProjectId] = useState(null);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const demandSync = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };
  const handleProject = (value) => {
    const project = projectIdList.find((project) => project === value);
    if (project !== undefined) {
      setProjectId(project);
      myLocalStorage.setItem("gcpSelectedProjectId", project);
    }
  };

  const fetchProjectIdList = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/auth/fetchAllProjectIds`,
      {
        params: {
          tenantName: latestTenant,
        },
      },
    );
    setProjectIdList(response.data);
  };

  useEffect(() => {
    if (projectIdList.length === 1) return setProjectId(projectIdList[0]);

    if (projectIdList?.length > 1) {
      const latestprojectId = myLocalStorage.getItem("gcpSelectedProjectId");

      const project = projectIdList.find(
        (project) => project === latestprojectId,
      );
      if (project) setProjectId(project);
      else setProjectId(projectIdList[0]);
    }
  }, [projectIdList]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);
  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);
  useEffect(() => {
    fetchProjectIdList();
  }, [latestTenant]);

  return (
    <>
      <Stack direction="column" gap={5} mb={3}>
        <Typography variant="h5" gutterBottom sx={{ fontSize: "18px" }}>
          GCP Resources
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <Stack direction="row" spacing={5}>
            {tenantList.length > 0 && (
              <Autocomplete
                disablePortal
                value={selectedTenantName || ""}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => handleTenantChange(value)}
              />
            )}
            {projectIdList?.length > 0 && (
              <Autocomplete
                disablePortal
                value={projectId || " "}
                options={projectIdList}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Projects" />
                )}
                onChange={(e, value) => handleProject(value)}
              />
            )}
          </Stack>

          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <TabContext value={tabValue}>
              <TabList onChange={handleChange}>
                <Tab
                  label="Resources"
                  value="Resources"
                  icon={<GrResources />}
                />
                <Tab label="Kubernetes" value="K8" icon={<SiKubernetes />} />
              </TabList>
            </TabContext>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            disabled={isSyncing}
            onClick={demandSync}
          >
            <SyncIcon />
            {isSyncing ? "Syncing" : "Sync"}
          </Button>
        </Box>
      </Stack>

      <TabContext value={tabValue}>
        <CustomTabPanel value={tabValue} index="Resources">
          <Stack direction="row" spacing={5}>
            <GCPInfraResources
              selectedTenantName={selectedTenantName}
              projectId={projectId}
            />
          </Stack>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index="K8">
          <Stack direction="row" spacing={5}>
            <K8Resources
              selectedTenantName={selectedTenantName}
              allapi={`/api/gcp/kubernetes-clusters?tenantName=${selectedTenantName}&projectId=${projectId}`}
              clusterlevelapi="/api/k8-resources/v1/get?level=CLUSTER"
              namespacelevelapi="/api/k8-resources/v1/get?level=NAMESPACE"
              k8ProviderType="GCP"
              filterId={projectId}
            />
          </Stack>
        </CustomTabPanel>
      </TabContext>
    </>
  );
};

export default GcpResources;
