import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const AddURLModal = ({ open, onClose, selectedTenantName, createdURL }) => {
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [authList, setAuthList] = useState([]);
  const [newUser, setNewUser] = useState({ email: "", password: "" });
  const [isManaged, setManaged] = useState(false);

  const validateUrl = (inputUrl) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)" +
        "((www\\.)?" +
        "(([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" +
        "(\\d{1,3}\\.){3}\\d{1,3}|" +
        "\\[?[a-fA-F\\d:]+\\])" +
        "(\\:\\d+)?" +
        "(\\/[-a-zA-Z\\d%@_.~+&:]*)*" +
        "(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?" +
        "(\\#[-a-zA-Z\\d_]*)?$",
      "i",
    );

    return pattern.test(inputUrl);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setIsValidUrl(null);
  };

  const handleValidateUrl = () => {
    let formattedUrl = url.trim();

    if (/^http:\/\//i.test(formattedUrl)) {
      setIsValidUrl(validateUrl(formattedUrl));
      return;
    }

    if (/^www\./i.test(formattedUrl)) {
      formattedUrl = "https://" + formattedUrl;
    } else if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = "https://" + formattedUrl;
    }

    /*
    else if (/^https?:\/\/(?!www\.)/i.test(formattedUrl)) {
      formattedUrl = formattedUrl.replace(/^(https?:\/\/)/i, "$1www.");
    } else if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = "https://www." + formattedUrl;
    }
    */

    setUrl(formattedUrl);
    setIsValidUrl(validateUrl(formattedUrl));
  };

  const handleUserChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleAddUser = () => {
    if (newUser.email && newUser.password) {
      setAuthList([...authList, newUser]);
      setNewUser({ email: "", password: "" });
    }
  };

  const handleRemoveUser = (index) => {
    setAuthList(authList.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const requestData = {
      url,
      tenantName: selectedTenantName,
      isManaged: isManaged,
      auth: authList,
    };
    const response = await axios
      .post(`${NGROK}/api/websites/createAuth`, requestData)
      .then((data) => {
        createdURL();
      })
      .catch((err) => console.error(err));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Web URLs</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Web URL"
          variant="outlined"
          value={url}
          onChange={handleUrlChange}
          error={isValidUrl === false}
          helperText={isValidUrl === false ? "Invalid URL" : ""}
          margin="dense"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleValidateUrl}
          sx={{ mt: 1, mb: 2 }}
          disabled={!url}
          fullWidth
        >
          Validate
        </Button>

        {isValidUrl && (
          <>
            <Box display="flex" flexDirection="column" gap={1} mt={2}>
              <TextField
                label="Email / Username"
                name="email"
                value={newUser.email}
                onChange={handleUserChange}
                fullWidth
                variant="outlined"
                margin="dense"
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={newUser.password}
                onChange={handleUserChange}
                fullWidth
                variant="outlined"
                margin="dense"
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={handleAddUser}
                disabled={!newUser.email || !newUser.password}
              >
                Add User
              </Button>
            </Box>

            {authList.length > 0 && (
              <Box mt={2}>
                {authList.map((user, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                    border="1px solid #ddd"
                    borderRadius={1}
                    mb={1}
                  >
                    <span>{user.email}</span>
                    <IconButton
                      onClick={() => handleRemoveUser(index)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isValidUrl || authList.length === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddURLModal;
