import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { azureIcons } from "../../AWS/AWSIcons";
import moment from "moment";
import useUserStore from "../../../../services/userStore";
import GCPRequestResourceAccessForm from "./GCPRequestResourceAccessForm";
import useNotificationsStore from "../../../../services/notificationStore";
import TimerForAWS from "../../../components/TimerForAWS";

const AccessGCPStorages = ({
  publishedStorageBuckets,
  projectId,
  gcpUserEmail,
}) => {
  const { role } = useUserStore((state) => state.user);
  const [selectedResource, setSelectedResource] = useState(null);
  const [onGCPRequestResourceAccessForm, setOnGCPRequestResourceAccessForm] =
    useState(false);
  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);
  const { userGcpNotifications } = useNotificationsStore();

  const handleClickRequestForm = (vm) => {
    setSelectedResource(vm);
    setOnGCPRequestResourceAccessForm(true);
  };
  const fetchResourceLevel = userGcpNotifications?.filter(
    (userAWSNotification) =>
      userAWSNotification.accessType === "resources" &&
      userAWSNotification.requestStatus === "APPROVED",
  );
  const checkAccessTiming = (name) => {
    return fetchResourceLevel.find((data) => data.accessName === name);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Location </TableCell>
              <TableCell>Created At</TableCell>
              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedStorageBuckets?.length > 0 ? (
              publishedStorageBuckets.map((storage, index) => {
                const accessData = checkAccessTiming(storage.name);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {azureIcons.strgs}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {storage.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {storage.location}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {moment(new Date()).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {role === "TENANT_USER" ? (
                      accessData ? (
                        <TableCell>
                          <TimerForAWS
                            accessTimeOverAt={accessData.accessTimeOverAt}
                            resourceId={storage?.name}
                            selectedTime={accessData.selectedTime}
                            setTimedResourcesWithoutAccess={
                              setTimedResourcesWithoutAccess
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickRequestForm(storage)}
                          >
                            Request Access
                          </Button>
                        </TableCell>
                      )
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No Storages available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedResource && onGCPRequestResourceAccessForm && (
        <GCPRequestResourceAccessForm
          open={onGCPRequestResourceAccessForm}
          onClose={() => {
            setOnGCPRequestResourceAccessForm(false);
            setSelectedResource(null);
          }}
          data={selectedResource}
          title={selectedResource?.name}
          projectId={projectId}
          gcpUserEmail={gcpUserEmail}
          from={"resources"}
          resource="STORAGE_BUCKET"
        />
      )}
    </>
  );
};

export default AccessGCPStorages;
