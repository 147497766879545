import React, { useEffect, useMemo, useState } from "react";
import { myLocalStorage } from "../../../components/StorageHelper";
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  Drawer,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import BloodHoundQueries from "./BloodHoundQueries";
import axios from "axios";
import { NGROK } from "../../../APIs";
import GraphComponent from "./GraphComponent";
import useUserStore from "../../../services/userStore";
import CloseIcon from "@mui/icons-material/Close";
import ReactLoading from "react-loading";
import { useGraphContext } from "./GraphContext";

const ADAnalytics = () => {
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [queryData, setQueryData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [selectedqueryName, setSelectedQueryName] = useState("");
  const [selectedNode, setSelectedNode] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const { setGraphloading, setIsgraphchanged } = useGraphContext();
  const [preloader, setPreloader] = useState(true);

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+|_|\-|\$)/g, (match, index) =>
        index === 0 ? match.toUpperCase() : match.toLowerCase(),
      )
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9]/g, "");
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const selectedQuery = async (matchquery) => {
    setLoading(true);

    setGraphloading(false);
    try {
      setSelectedQueryName(matchquery?.description);
      const response = await axios.post(`${NGROK}/api/bloodhound/query/run`, {
        cypherQuery: matchquery?.query,
        includeProperties: matchquery?.includeProperties,
      });
      if (response?.data) {
        setQueryData(response?.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  useEffect(() => {
    setSelectedNode(null);
    setPreloader(true);
    const timer = setTimeout(() => {
      setPreloader(false);
    }, 3000);
  }, [queryData]);

  return (
    <>
      <Stack>
        <Box display="flex" justifyContent="flex-start">
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {tenantList?.length > 0 ? (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={selectedTenantName ? selectedTenantName : " "}
                    options={tenantList.map((tenant) => tenant.tenantName)}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tenant List" />
                    )}
                    onChange={(e, value) => {
                      handleTenantChange(value);
                    }}
                  />
                ) : null}
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Box display="flex" height="100%">
            {!expanded && (
              <Box
                sx={{
                  width: "10%",
                  transition: "width 0.3s ease",
                  height: "70vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box display="flex" alignItems="center" height="100%">
                  <IconButton
                    onClick={toggleExpanded}
                    sx={{
                      zIndex: 1,
                      border: "1px solid gray",
                    }}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                width: expanded ? "30%" : "0",
                transition: "width 0.3s ease",
                height: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box display="flex" alignItems="center" height="100%">
                <IconButton
                  onClick={toggleExpanded}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: expanded ? "-16px" : "-32px",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                    backgroundColor: "white",
                    border: "1px solid gray",
                  }}
                >
                  {expanded ? <ArrowBackIos /> : <ArrowForwardIos />}
                </IconButton>
                <Box p={2} sx={{ visibility: expanded ? "visible" : "hidden" }}>
                  <BloodHoundQueries
                    tenantName={latestTenant}
                    selectedQuery={selectedQuery}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                transition: "all 0.3s ease",
                width: expanded ? "calc(100% - 30%)" : "100%",
                height: "65vh",
                padding: 2,
              }}
            >
              <Stack direction={"column"} alignItems={"center"} spacing={2}>
                <Typography variant="h4">{selectedqueryName}</Typography>

                <GraphComponent
                  graphData={queryData}
                  setExpanded={setExpanded}
                  setSelectedNode={setSelectedNode}
                  preloader={preloader}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Drawer
        variant="persistent"
        anchor="right"
        open={!!selectedNode}
        style={{ width: 300, flexShrink: 0 }}
      >
        <div style={{ width: 300, padding: 20 }}>
          <IconButton
            onClick={() => {
              setExpanded(false);
              setSelectedNode(null);
            }}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <h2>Node Properties</h2>
          {selectedNode ? (
            <Card>
              <CardContent>
                <Typography variant="h6">{selectedNode.label}</Typography>
                <List>
                  {Object.entries(selectedNode.properties).map(
                    ([key, value]) => {
                      const cameCase = toCamelCase(key);

                      return (
                        <ListItem key={key}>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>{cameCase}:</strong> {value}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    },
                  )}
                </List>
              </CardContent>
            </Card>
          ) : (
            <p>No node selected</p>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default ADAnalytics;
