import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import RequestK8TableBody from "./RequestK8TableBody";

const RequestsK8Table = ({ adminNotifications }) => {
  const sortedNotification = adminNotifications.sort(
    (a, b) => new Date(b.requestedAt) - new Date(a.requestedAt),
  );
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          border: 1,
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
        }}
      >
        <Table
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Requested By
              </TableCell>

              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Cluster Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Resource Type
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Resource Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Verbs
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Custom Created
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Justification
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Privilege Level
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <RequestK8TableBody adminNotifications={sortedNotification} />
        </Table>
      </TableContainer>
    </>
  );
};

export default RequestsK8Table;
