import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { NGROK } from "../../../APIs";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const getDynamicMaxHeight = () => {
  const screenHeight = window.innerHeight;
  const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
  return screenHeight - offsetTop - 200;
};

const useStyles = makeStyles({
  root: {
    maxHeight: getDynamicMaxHeight(),
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});

const BloodHoundQueries = ({ tenantName, selectedQuery }) => {
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectQuery, setSelectQuery] = useState(null);
  const classes = useStyles();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const fetchBlodHoundQueries = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/bloodhound/query/get-all?tenantName=${tenantName}`,
      );
      if (response?.data) {
        setQueries(response?.data);
        if (response.data.length > 0) {
          setSelectQuery(response.data[0]);
          selectedQuery(response.data[0]);
        }
      }
    } catch (e) {
      setQueries([]);
    }

    setLoading(false);
  };

  const filterequeryDatas = queries?.filter((query) =>
    query.description.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (tenantName != null) fetchBlodHoundQueries(true, tenantName);
  }, [tenantName]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "auto",
          height: "100%",
        }}
        className={classes.root}
      >
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
              position: "sticky",
              top: 0,
              zIndex: 1,
            },
            "& td, & th": {
              borderBottom: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
          aria-label="sticky table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} align="left">
                Query list
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filterequeryDatas.length > 0 ? (
              <>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: "54px",
                    backgroundColor: "#f5f5f5",
                    zIndex: 1,
                  }}
                >
                  <TableCell colSpan={7}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Query"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>

                {filterequeryDatas.map((query, index) => (
                  <TableRow
                    key={index}
                    selected={query?.id === selectQuery?.id}
                    onClick={() => {
                      setSelectQuery(query);
                      selectedQuery(query);
                    }}
                    sx={{
                      "&:hover": {
                        background: "#f5f5f5",
                        cursor: "pointer",
                      },
                      "&.Mui-selected": {
                        background: "#233044 !important",
                        "& td, & th": {
                          color: "#fff",
                        },
                      },
                      "& td, & th": {
                        borderBottom: "1px solid #233044",
                        fontSize: "18px",
                      },
                    }}
                  >
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {" "}
                            {query.description}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography align="center">No Query Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BloodHoundQueries;
