import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NGROK } from "../../../../APIs";

const ExpandableUrlDetailsTableRow = ({ row }) => {
  const [open, setOpen] = useState(false);

  const handleViewClick = async (zipName, file) => {
    try {
      const generatedUrl = `${NGROK}/api/websites/stream-video?zipFile=${zipName}&videoFile=${file}`;

      const form = document.createElement("form");
      form.method = "GET";
      form.action = `${window.location.origin}/video-player`;
      form.target = "_blank";

      const params = {
        url: generatedUrl,
        zipFile: zipName,
        videoFile: file,
      };

      Object.keys(params).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    } catch (error) {
      console.error("Failed to fetch video:", error);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">{row.userEmail || "-"}</TableCell>
        <TableCell align="center">{row.credentialEmail || "-"}</TableCell>
        <TableCell align="center">{row.publicIp || "-"}</TableCell>
        <TableCell align="center">{row.startDate}</TableCell>
        <TableCell align="center">{row.endDate}</TableCell>
        <TableCell align="center">{row.duration}</TableCell>
        <TableCell
          style={{ cursor: "pointer", color: "blue" }}
          align="center"
          onClick={() => setOpen(!open)}
        >
          {row.fileCount}
        </TableCell>
      </TableRow>

      <TableRow sx={{ display: open ? "table-row" : "none", border: "none" }}>
        <TableCell colSpan={4} sx={{ border: "none" }}></TableCell>
        <TableCell colSpan={3} sx={{ padding: "8px", border: "none" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "4px", padding: "8px" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textAlign: "left",
                  fontWeight: "bold",
                  marginBottom: "4px",
                }}
              >
                Videos
              </Typography>

              <Table
                size="small"
                sx={{
                  width: "100%",
                  border: "none",
                  "& td, & th": { border: "none" },
                }}
                component="div"
              >
                <TableBody>
                  {row.files.map((file, index) => (
                    <TableRow key={index} component="div">
                      <TableCell
                        component="div"
                        sx={{
                          width: "5%",
                          textAlign: "left",
                          padding: "4px 6px",
                          border: "none",
                        }}
                      >
                        {index + 1}.
                      </TableCell>

                      <TableCell
                        component="div"
                        sx={{
                          textAlign: "left",
                          padding: "4px 6px",
                          border: "none",
                        }}
                      >
                        <Typography variant="body2" sx={{ fontSize: 14 }}>
                          {file}
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="div"
                        sx={{
                          width: "10%",
                          textAlign: "right",
                          padding: "4px 6px",
                          border: "none",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleViewClick(row.zipName, file)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpandableUrlDetailsTableRow;
