import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import InfrastructureResources from "./Components/InfrastructureResources";
import { TabContext, TabList } from "@mui/lab";
import K8Resources from "./Components/K8Resources";
import { SiKubernetes } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import { CustomTabPanel } from "../../../GenericMethods";

const AzureResources = () => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [tabValue, setTabValue] = useState("Resources");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const demandSync = async () => {
    await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: { tenantName: selectedTenantName },
    });
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;
      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      setSelectedTenantName(
        tenant ? tenant.tenantName : tenantList[0].tenantName,
      );
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Stack direction="column" gap={5} mb={3}>
        <Typography variant="h5" gutterBottom sx={{ fontSize: "18px" }}>
          Azure Resources
        </Typography>

        {/* Main Row: Autocomplete, Tabs, Sync Button */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          {tenantList.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName || ""}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => handleTenantChange(value)}
            />
          )}

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <TabContext value={tabValue}>
              <TabList onChange={handleChange}>
                <Tab
                  label="Resources"
                  value="Resources"
                  icon={<GrResources />}
                />
                <Tab label="Kubernetes" value="K8" icon={<SiKubernetes />} />
              </TabList>
            </TabContext>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            onClick={demandSync}
          >
            <SyncIcon />
            Sync
          </Button>
        </Box>
      </Stack>

      <TabContext value={tabValue}>
        <CustomTabPanel value={tabValue} index="Resources">
          <Stack direction="row" spacing={5}>
            <InfrastructureResources selectedTenantName={selectedTenantName} />
          </Stack>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index="K8">
          <Stack direction="row" spacing={5}>
            <K8Resources
              selectedTenantName={selectedTenantName}
              allapi={`/api/azureResources/v1/all?type=AZURE_KUBERNETES&tenantName=${selectedTenantName}`}
              clusterlevelapi="/api/k8-resources/v1/get?level=CLUSTER"
              namespacelevelapi="/api/k8-resources/v1/get?level=NAMESPACE"
              k8ProviderType="AZURE"
              filterId="SubId"
            />
          </Stack>
        </CustomTabPanel>
      </TabContext>
    </>
  );
};

export default AzureResources;
