import React, { useEffect, useState } from "react";
import AzureVMInstances from "./AzureVMInstances";
import AzureStorages from "./AzureStorages";
import AzureDatabase from "./AzureDatabase";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useUserStore from "../../../../services/userStore";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";

const azureResources = [
  {
    id: "VM",
    name: "Virtual Machines",
    icon: azureIcons.vmLarge,
    description:
      "Azure Virtual Machines provide on-demand, scalable computing resources with a range of operating systems and configurations to choose from.",
  },
  {
    id: "STRGS",
    name: "Storages",
    icon: azureIcons.strgs,
    description:
      "Azure Storage offers secure, massively scalable cloud storage for data objects, files, and applications, accessible via various protocols.",
  },
  {
    id: "DB",
    name: "Databases",
    icon: awsIcons.rdsLarge,
    description:
      "Azure Databases include a wide variety of database services, such as SQL Database, Cosmos DB, and Database for MySQL, to support different data requirements.",
  },
];

const InfrastructureResources = ({ selectedTenantName }) => {
  const [selectedResource, setSelectedResource] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAzureResources = azureResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const fetchInstanceFortheResource = async (resource) => {
    setSelectedResource(resource);
  };

  useEffect(() => {
    if (azureResources.length > 0) {
      setSelectedResource(azureResources[0]);
      fetchInstanceFortheResource(azureResources[0]);
    }
  }, []);
  return (
    <>
      <Box width="30%">
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell>Resource Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={1}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Resources"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                  />
                </TableCell>
              </TableRow>

              {filteredAzureResources.map((resource) => (
                <TableRow
                  key={resource.name}
                  selected={resource === selectedResource}
                  onClick={() => fetchInstanceFortheResource(resource)}
                  sx={{
                    "&:hover": {
                      background: "#f5f5f5",
                      cursor: "pointer",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "8px",
                    },
                    "&.Mui-selected": {
                      background: "#233044 !important",
                      "& td, & th": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <TableCell>
                    <Stack
                      direction={"row"}
                      spacing={5}
                      display="flex"
                      alignItems="center"
                    >
                      <div>{resource.icon}</div>

                      <Box ml={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          {resource.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: "350px",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {resource.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box width="70%">
        {selectedResource?.id === "VM" && (
          <AzureVMInstances tenantName={selectedTenantName} />
        )}
        {selectedResource?.id === "STRGS" && (
          <AzureStorages tenantName={selectedTenantName} />
        )}
        {selectedResource?.id === "DB" && (
          <AzureDatabase tenantName={selectedTenantName} />
        )}
      </Box>
    </>
  );
};

export default InfrastructureResources;
