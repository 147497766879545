import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Edit, Delete, Add } from "@mui/icons-material";
import { NGROK } from "../../../../APIs";
import axios from "axios";

const EditAuth = ({ website, selectedTenant, handleUpdateUrlPopup }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(website?.url || "");
  const [authList, setAuthList] = useState(website?.auth || []);
  const [removedAuth, setRemovedAuth] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [newUser, setNewUser] = useState({ email: "", password: "" });

  const handleUrlChange = (e) => setUrl(e.target.value);

  const handleRemoveUser = (index) => {
    const updatedList = [...authList];
    const removedItem = updatedList.splice(index, 1)[0];
    setAuthList(updatedList);
    setRemovedAuth([...removedAuth, removedItem]);
  };

  const handleAddUser = () => {
    if (newUser.email && newUser.password) {
      const newAuthUser = {
        id: Date.now(),
        email: newUser.email,
        password: newUser.password,
      };
      setAuthList([...authList, newAuthUser]);
      setNewUser({ email: "", password: "" });
    }
  };

  const handleEditUser = (user) => {
    setEditUser(user);
    setAuthList((prevList) =>
      prevList.map((u) => (u.id === user.id ? { ...u, isUpdated: true } : u)),
    );
  };

  const handleSaveEdit = () => {
    setAuthList(
      authList.map((user) =>
        user.id === editUser.id
          ? { ...user, email: editUser.email, password: editUser.password }
          : user,
      ),
    );
    setEditUser(null);
  };

  const handleClose = () => {
    setOpen(false);
    setAuthList([]);
    setUrl("");
    setRemovedAuth([]);
    setEditUser(null);
    setNewUser({ email: "", password: "" });
  };

  const handleSubmit = async () => {
    const updatedAuth = [];
    const newAuth = [];
    const removedAuth = [];

    authList.forEach((user) => {
      const originalUser = website.auth.find((old) => old.id === user.id);
      if (originalUser) {
        if (user.email !== originalUser.email || user.isUpdated) {
          if (!updatedAuth.some((u) => u.email === user.email)) {
            updatedAuth.push({
              id: user.id,
              email: user.email,
              password: user.password,
              authValutTags: user.authValutTags,
            });
          }
        }
      } else {
        if (!newAuth.some((u) => u.email === user.email)) {
          newAuth.push({
            id: user.id,
            email: user.email,
            password: user.password,
          });
        }
      }
    });

    website.auth.forEach((originalUser) => {
      const exists = authList.some((user) => user.id === originalUser.id);
      if (!exists) {
        if (!removedAuth.some((u) => u.email === originalUser.email)) {
          removedAuth.push({
            id: originalUser.id,
            email: originalUser.email,
            authValutTags: originalUser.authValutTags,
          });
        }
      }
    });

    const payload = {
      id: website.id,
      url,
      updatedauth: updatedAuth,
      newauth: newAuth,
      removedauth: removedAuth,
    };

    console.log("Payload:", JSON.stringify(payload, null, 2));

    try {
      const response = await axios.put(
        `${NGROK}/api/websites/updateAuth?tenantName=${selectedTenant}`,
        payload,
      );

      if (response) {
        handleUpdateUrlPopup();
      }

      handleClose();
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    if (open) {
      setAuthList(website?.auth);
      setUrl(website?.url);
      setRemovedAuth([]);
      setEditUser(null);
      setNewUser({ email: "", password: "" });
    }
  }, [open]);

  return (
    <>
      <Tooltip title="Edit">
        <Button
          color="primary"
          variant="outlined"
          startIcon={<Edit />}
          onClick={() => setOpen(true)}
        >
          Edit
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Web URL</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Web URL"
            variant="outlined"
            value={url}
            onChange={handleUrlChange}
            margin="dense"
            disabled
          />

          <Box mt={2}>
            {authList.map((user, index) => (
              <Box
                key={user.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                border="1px solid #ddd"
                borderRadius={1}
                mb={1}
              >
                <Typography>{user.email}</Typography>
                <Box>
                  <IconButton
                    onClick={() => handleEditUser(user)}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveUser(index)}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>

          <Typography variant="h6" mt={3}>
            Add New User
          </Typography>
          <Box display="flex" flexDirection="column" gap={1} mt={1}>
            <TextField
              label="Email / Username"
              name="email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              fullWidth
              variant="outlined"
              margin="dense"
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              fullWidth
              variant="outlined"
              margin="dense"
            />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              onClick={handleAddUser}
              disabled={!newUser.email || !newUser.password}
            >
              Add User
            </Button>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {editUser && (
        <Dialog
          open={Boolean(editUser)}
          onClose={() => setEditUser(null)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Edit Authentication</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Email / Username"
              variant="outlined"
              value={editUser.email}
              onChange={(e) =>
                setEditUser({ ...editUser, email: e.target.value })
              }
              margin="dense"
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={editUser.password}
              onChange={(e) =>
                setEditUser({ ...editUser, password: e.target.value })
              }
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditUser(null)}>Cancel</Button>
            <Button
              onClick={handleSaveEdit}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EditAuth;
