import React, { createContext, useContext, useState } from "react";

const GraphContext = createContext();

export const useGraphContext = () => useContext(GraphContext);

export const GraphProvider = ({ children }) => {
  const [graphLoading, setGraphloading] = useState(false);
  const [isgraphchanged, setIsgraphchanged] = useState(false);

  return (
    <GraphContext.Provider
      value={{
        graphLoading,
        setGraphloading,
        isgraphchanged,
        setIsgraphchanged,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};
