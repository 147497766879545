import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import PolicyIcon from "@mui/icons-material/Policy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import WebIcon from "@mui/icons-material/Web";
import {
  LabelOutlined,
  DescriptionOutlined,
  DvrOutlined,
  StorageOutlined,
  SaveOutlined,
} from "@mui/icons-material";

export const clusterType = [
  "NAMESPACE",
  "CUSTOM_RESOURCE_DEFINITION",
  "NODE",
  "STORAGE_CLASS",
  "PERSISTENT_VOLUME",
];

export const nameSpaceLevelType = [
  { name: "DEPLOYMENT", icon: <BusinessCenterIcon /> },
  { name: "SERVICE_ACCOUNT", icon: <AccountCircleIcon /> },
  { name: "SECRET", icon: <LockIcon /> },
  { name: "CONFIG_MAP", icon: <SettingsIcon /> },
  { name: "NETWORK_POLICY", icon: <PolicyIcon /> },
  { name: "JOB", icon: <AssignmentIcon /> },
  { name: "CRON_JOB", icon: <ScheduleIcon /> },
  { name: "REPLICA_SET", icon: <AppRegistrationIcon /> },
  { name: "STATEFUL_SET", icon: <CloudQueueIcon /> },
  { name: "DAEMON_SET", icon: <DashboardIcon /> },
  { name: "SERVICE", icon: <LocalOfferIcon /> },
  { name: "INGRESS", icon: <WebIcon /> },
];

export const KubernetesIcons = {
  NAMESPACE: <LabelOutlined />,
  CUSTOM_RESOURCE_DEFINITION: <DescriptionOutlined />,
  NODE: <DvrOutlined />,
  STORAGE_CLASS: <StorageOutlined />,
  PERSISTENT_VOLUME: <SaveOutlined />,
};
