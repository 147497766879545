import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress, Typography } from "@mui/material";
import { myLocalStorage } from "../../../../components/StorageHelper";

const VideoPlayer = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const videoUrl = searchParams.get("url");
  const zipFile = searchParams.get("zipFile");
  const videoFile = searchParams.get("videoFile");
  const navigate = useNavigate();

  const [finalUrl, setFinalUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getVideoURL = async () => {
    const storedVideoUrl = sessionStorage.getItem("URL") || videoUrl;
    const token =
      sessionStorage.getItem("authToken") ||
      myLocalStorage.getItem("access_token");

    if (!storedVideoUrl || !token) {
      setError("Missing video URL or authentication token.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(storedVideoUrl, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });

      const fileURL = URL.createObjectURL(response.data);
      setFinalUrl(fileURL);
    } catch (err) {
      setError("Failed to load video");
      console.error("Error fetching video:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem("URL")) {
      sessionStorage.setItem("URL", videoUrl);
      sessionStorage.setItem("zipFile", zipFile);
      sessionStorage.setItem("videoFile", videoFile);
    }

    sessionStorage.setItem("authToken", myLocalStorage.getItem("access_token"));

    getVideoURL();
  }, [videoUrl]);

  useEffect(() => {
    navigate(`${location.pathname}?url=${sessionStorage.getItem("URL")}`, {
      replace: true,
    });
  }, [searchParams]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      bgcolor={loading ? "white" : "black"}
    >
      <Typography
        variant="h4"
        sx={{
          color: loading ? "black" : "white",
          textAlign: "center",
          position: "absolute",
          top: 20,
          width: "100%",
        }}
      >
        Web URL Session
      </Typography>

      {loading ? (
        <CircularProgress color="inherit" />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box
          component="video"
          controls
          autoPlay
          sx={{
            width: "80%",
            height: "100%",
            maxHeight: "90vh",
            objectFit: "cover",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <source src={finalUrl} type="video/webm" />
          Your browser does not support the video tag.
        </Box>
      )}
    </Box>
  );
};

export default VideoPlayer;
