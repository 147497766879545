import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import AddURLModal from "./Components/AddURLModal";
import ConfiguredURLs from "./Components/ConfiguredURLs";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import SearchIcon from "@mui/icons-material/Search";

const WebUrls = () => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [addUrlConfig, setAddUrlConfig] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [macprogress, setMacProgress] = useState(0);
  const [macdownloading, setMacDownloading] = useState(false);
  const [addSnack, setAddSnack] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const downloadFile = async (os) => {
    try {
      if (os === "mac") {
        setMacProgress(0);
        setMacDownloading(true);
      } else {
        setProgress(0);
        setDownloading(true);
      }

      const response = await axios.get(
        `${NGROK}/api/websites/download?os=${os}`,
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              if (os === "mac") {
                setMacProgress(percent);
              } else {
                setProgress(percent);
              }
            }
          },
        },
      );

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "WSWebApp.zip");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      if (os === "mac") {
        setMacDownloading(false);
        setMacProgress(100);
      } else {
        setDownloading(false);
        setProgress(100);
      }
    } catch (error) {
      console.error("Error downloading the file", error);
      if (os === "mac") {
        setMacProgress(0);
        setMacDownloading(false);
      } else {
        setProgress(0);
        setDownloading(false);
      }
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const createdURL = () => {
    setAddSnack(true);
  };

  useEffect(() => {
    if (tenantList.length === 1) {
      setSelectedTenantName(tenantList[0].tenantName);
    } else if (tenantList.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;
      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      setSelectedTenantName(
        tenant ? tenant.tenantName : tenantList[0].tenantName,
      );
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Snackbar
        open={addSnack}
        autoHideDuration={6000}
        onClose={() => setAddSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAddSnack(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Created Web Url
        </Alert>
      </Snackbar>
      <Stack spacing={5}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Stack direction={"row"} spacing={5}>
              {tenantList.length > 0 && (
                <Autocomplete
                  disablePortal
                  value={selectedTenantName || " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => handleTenantChange(value)}
                />
              )}
              <TextField
                placeholder="Search URLs..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                startIcon={<FaApple />}
                onClick={() => downloadFile("mac")}
                disabled={macdownloading}
                sx={{ position: "relative", minWidth: 200 }}
              >
                {macdownloading ? (
                  <>
                    {macprogress > 0
                      ? `${macprogress}% Downloaded`
                      : "Downloading..."}
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  </>
                ) : (
                  "Download Zip"
                )}
              </Button>

              <Button
                variant="outlined"
                startIcon={<FaWindows />}
                onClick={() => downloadFile("windows")}
                disabled={downloading}
                sx={{ position: "relative", minWidth: 200 }}
              >
                {downloading ? (
                  <>
                    {progress > 0
                      ? `${progress}% Downloaded`
                      : "Downloading..."}
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  </>
                ) : (
                  "Download Zip"
                )}
              </Button>
            </Stack>

            {userData.role !== "TENANT_USER" && (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setAddUrlConfig(true)}
              >
                Add URL
              </Button>
            )}
          </Box>
        </Box>
        <Typography variant="h5" component="div">
          <Stack direction={"column"} spacing={2} display={"flex"} mt={2}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 16,
              }}
            >
              Make sure that the zip is downloaded and WSWebApp is running on
              the end machine where URL will be accessed.
            </Typography>
          </Stack>
        </Typography>
        <ConfiguredURLs
          selectedTenantName={selectedTenantName}
          searchTerm={searchTerm}
        />
      </Stack>
      {addUrlConfig && (
        <AddURLModal
          open={addUrlConfig}
          onClose={() => setAddUrlConfig(false)}
          selectedTenantName={selectedTenantName}
          createdURL={createdURL}
        />
      )}
    </>
  );
};

export default WebUrls;
