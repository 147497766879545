import React, { useEffect, useRef, useState } from "react";
import { SigmaContainer, useSigma } from "@react-sigma/core";
import { NodeImageProgram } from "@sigma/node-image";
import Graph from "graphology";
import forceAtlas2 from "graphology-layout-forceatlas2";
import "@react-sigma/core/lib/style.css";
import {
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactLoading from "react-loading";
import { useGraphContext } from "./GraphContext";

const getDynamicMaxHeight = () => {
  const screenHeight = window.innerHeight;
  const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
  return screenHeight - offsetTop - 200;
};

const sigmaStyle = { height: getDynamicMaxHeight(), width: "100%" };

const settings = {
  nodeProgramClasses: {
    image: NodeImageProgram,
  },
  renderEdgeLabels: true,
  defaultEdgeType: "arrow",
  minArrowSize: 10,
  edgeColor: "default",
  defaultEdgeColor: "#ccc",
  barnesHutOptimize: true,
  enableEdgeHoverHighlight: true,
  labelThreshold: 10,
  allowInvalidContainer: true,
};

const imagePaths = {
  User: "/assets/BloodhoundImages/Usernode.svg",
  Group: "/assets/BloodhoundImages/Groupnode.svg",
  App: "/assets/BloodhoundImages/AZAppnode.svg",
  OU: "/assets/BloodhoundImages/OUnode.svg",
  Domain: "/assets/BloodhoundImages/DomainNode.svg",
  Container: "/assets/BloodhoundImages/AZResourceGroupnode.svg",
  Computer: "/assets/BloodhoundImages/AZDevicenode.svg",
  Default: "/assets/BloodhoundImages/DomainNode.svg",
};

const getCentralPosition = () => ({
  x: 50,
  y: 50,
});

const getRandomAngle = () => {
  const minAngle = 20;
  const maxAngle = 360;

  let angle = Math.random() * (maxAngle - minAngle) + minAngle;

  const alignmentStep = 20;
  const alignedAngle = Math.round(angle / alignmentStep) * alignmentStep;

  return alignedAngle;
};

const doesIntersect = (a, b, c, d) => {
  const det = (a, b, c) =>
    (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
  const det1 = det(a, b, c);
  const det2 = det(a, b, d);
  const det3 = det(c, d, a);
  const det4 = det(c, d, b);
  return det1 * det2 < 0 && det3 * det4 < 0;
};

const adjustLabelPosition = (node, graph) => {
  const nodePosition = graph.getNodeAttributes(node);
  const labels = nodePosition.label.split(",");
  const labelHeight = 25;
  const labelSpacing = 10;

  let yOffset = 0;

  labels.forEach((label, index) => {
    const labelWidth = label.length * 7;
    const labelX = nodePosition.x - labelWidth / 2;
    const labelY = nodePosition.y + yOffset;

    graph.setNodeAttribute(node, `labelX-${index}`, labelX);
    graph.setNodeAttribute(node, `labelY-${index}`, labelY);

    yOffset += labelHeight + labelSpacing;
  });
};

const isNodeWithinRadius = (x, y, graph, radius) => {
  let withinRadius = false;
  graph.forEachNode((otherNode) => {
    const otherX = graph.getNodeAttribute(otherNode, "x");
    const otherY = graph.getNodeAttribute(otherNode, "y");
    const distance = Math.sqrt(
      Math.pow(x - otherX, 2) + Math.pow(y - otherY, 2),
    );
    if (distance < radius) {
      withinRadius = true;
    }
  });
  return withinRadius;
};

const adjustNodePosition = (node, graph, attempts = 0) => {
  if (attempts > 100) return;

  const angle = getRandomAngle();
  const currentX = graph.getNodeAttribute(node, "x");
  const currentY = graph.getNodeAttribute(node, "y");

  const degree = graph.degree(node);
  const distanceFactor = 50 + degree * 10;

  const parentEdges = graph.inEdges(node);
  const isParent = parentEdges.length > 0;
  const hasChildren = graph.outDegree(node) > 0;
  const maxChildDistance = degree === 1 ? 15 : 60;

  let distance = Math.min(distanceFactor, maxChildDistance);

  if (hasChildren) {
    distance = Math.min(Math.max(distance, 500), 10000);
  } else {
    distance = Math.min(distanceFactor, maxChildDistance);
  }

  let newX = currentX + distance * Math.cos(angle);
  let newY = currentY + distance * Math.sin(angle);

  if (isNaN(newX) || isNaN(newY)) {
    console.error(`New positions are invalid: x=${newX}, y=${newY}`);
    return;
  }

  if (isNodeWithinRadius(newX, newY, graph, 50)) {
    adjustNodePosition(node, graph, attempts + 1);
  } else {
    graph.setNodeAttribute(node, "x", newX);
    graph.setNodeAttribute(node, "y", newY);
  }
};

const LoadGraph = ({ jsonData, nodeClicked, preloader }) => {
  const { graphLoading, setGraphloading, setIsgraphchanged, isgraphchanged } =
    useGraphContext();
  const sigma = useSigma();
  if (preloader) {
    const existingGraph = sigma.getGraph();
    if (existingGraph && existingGraph.order > 0) {
      console.log("Clearing existing graph...");
      sigma.setGraph(new Graph());
    }
  }

  const graph = new Graph({ multi: true });
  useEffect(() => {
    try {
      Object.keys(jsonData.data.nodes).forEach((nodeId) => {
        const node = jsonData.data.nodes[nodeId];
        const imagePath = imagePaths[node.kind] || imagePaths["Default"];
        const nodeColor = node.isTierZero
          ? "#FF4136"
          : node.kind === "User"
            ? "#0074D9"
            : node.kind === "Group"
              ? "#2ECC40"
              : "#FFDC00";

        const initialX = 50;
        const initialY = 50;

        graph.addNode(nodeId, {
          label: node.label,
          size: node.kind === "Group" ? 22 : 20,
          color: nodeColor,
          type: "image",
          image: imagePath,
          cursor: "pointer",
          properties: node.properties,
          x: initialX,
          y: initialY,
        });
      });

      if (jsonData.data.edges && jsonData.data.edges.length > 0) {
        jsonData.data.edges.forEach((edge, index) => {
          if (graph.hasNode(edge.source) && graph.hasNode(edge.target)) {
            const edgeKey = `${edge.source}-${edge.target}-${index}`;
            graph.addEdgeWithKey(edgeKey, edge.source, edge.target, {
              label: edge.label,
              color: edge.label === "MemberOf" ? "black" : "#aaa",
              size: 3,
              type: "arrow",
              weight: 2,
            });
          }
        });
      }

      let commonNode = null;
      let maxConnections = 0;

      graph.forEachNode((node) => {
        const degree = graph.degree(node);
        if (degree > maxConnections && degree > 1) {
          maxConnections = degree;
          commonNode = node;
        }
      });

      const centralPosition = getCentralPosition();
      if (commonNode) {
        graph.setNodeAttribute(commonNode, "x", centralPosition.x);
        graph.setNodeAttribute(commonNode, "y", centralPosition.y);
      }

      graph.forEachNode((node) => {
        if (node !== commonNode) {
          const parentEdges = graph.inEdges(node);
          const degree = graph.degree(node);
          const hasChildren = graph.outDegree(node) > 0;
          const distance = hasChildren ? 300 : 100 + degree * 10;

          let angle = getRandomAngle();
          let x = centralPosition.x + distance * Math.cos(angle);
          let y = centralPosition.y + distance * Math.sin(angle);

          if (parentEdges.length > 0) {
            const parentEdge = parentEdges[0];
            const parent = graph.source(parentEdge);
            const parentPosition = {
              x: graph.getNodeAttribute(parent, "x"),
              y: graph.getNodeAttribute(parent, "y"),
            };

            x = parentPosition.x + distance * Math.cos(angle);
            y = parentPosition.y + distance * Math.sin(angle);
          }

          graph.setNodeAttribute(node, "x", x);
          graph.setNodeAttribute(node, "y", y);
          adjustNodePosition(node, graph);
        }
      });

      graph.forEachNode((node) => {
        adjustLabelPosition(node, graph);
      });

      forceAtlas2.assign(graph, {
        iterations: 200,
        settings: {
          gravity: 0.1,
          scalingRatio: 1.5,
          strongGravityMode: true,
          slowDown: 1.5,
          linLogMode: false,
        },
      });

      sigma.setGraph(graph);

      const camera = sigma.getCamera();

      const checkPosition = setInterval(() => {
        const displayData = sigma.getNodeDisplayData(commonNode);

        if (displayData) {
          const { x, y } = displayData;

          if (!isNaN(x) && !isNaN(y)) {
            clearInterval(checkPosition);

            camera.animate(
              {
                x: x,
                y: y,
                ratio: 0.7,
              },
              {
                duration: 800,
                easing: "linear",
              },
            );
          }
        }
      }, 100);

      sigma.on("clickNode", (event) => {
        const nodeId = event.node;
        if (graph.hasNode(nodeId)) {
          const node = graph.getNodeAttributes(nodeId);
          nodeClicked(node);
          setGraphloading(false);
        } else {
          console.error(`Node with ID "${nodeId}" not found in the graph.`);
        }
      });
    } catch (err) {
      console.error("Error loading graph:", err);
    }

    setGraphloading(false);
    setIsgraphchanged(false);
  }, [jsonData, sigma, preloader]);

  return null;
};

const GraphComponent = ({ graphData, setSelectedNode, preloader }) => {
  const { graphLoading, isgraphchanged, setIsgraphchanged } = useGraphContext();
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsgraphchanged(true);
  }, [graphData]);

  const nodeClicked = (node) => {
    setSelectedNode(node);
  };

  return (
    <>
      {preloader && (
        <ReactLoading type="spokes" color="#000" height={50} width={50} />
      )}
      <SigmaContainer style={sigmaStyle} settings={settings}>
        <LoadGraph
          jsonData={graphData}
          nodeClicked={nodeClicked}
          preloader={preloader}
        />
      </SigmaContainer>
    </>
  );
};

export default GraphComponent;
