import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";

import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Tab,
  Stack,
} from "@mui/material";

import { awsIcons } from "../AWS/AWSIcons";
import { CustomTabPanel } from "../../../GenericMethods";
import { TabContext, TabList } from "@mui/lab";
import { SiKubernetes } from "react-icons/si";
import K8Roles from "../Azure/Components/K8Roles";
import GCPGenericRoles from "./Components/GCPGenericRoles";

const GcpRoles = () => {
  const [tabValue, setTabValue] = useState("Roles");

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [projectIdList, setProjectIdList] = useState([]);

  const [projectId, setProjectId] = useState(null);

  const userData = useUserStore((state) => state.user);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };
  const handleProject = (value) => {
    const project = projectIdList.find((project) => project === value);
    if (project !== undefined) {
      setProjectId(project);
      myLocalStorage.setItem("gcpSelectedProjectId", project);
    }
  };

  const fetchProjectIdList = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/auth/fetchAllProjectIds`,
      {
        params: {
          tenantName: latestTenant,
        },
      },
    );
    setProjectIdList(response.data);
  };

  useEffect(() => {
    if (projectIdList.length === 1) return setProjectId(projectIdList[0]);

    if (projectIdList?.length > 1) {
      const latestprojectId = myLocalStorage.getItem("gcpSelectedProjectId");

      const project = projectIdList.find(
        (project) => project === latestprojectId,
      );
      if (project) setProjectId(project);
      else setProjectId(projectIdList[0]);
    }
  }, [projectIdList]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  useEffect(() => {
    fetchProjectIdList();
  }, [latestTenant]);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          GCP Roles
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <Stack direction={"row"} spacing={5}>
            {tenantList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName ? selectedTenantName : " "}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            ) : null}
            {projectIdList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={projectId ? projectId : " "}
                options={projectIdList}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Projects" />
                )}
                onChange={(e, value) => {
                  handleProject(value);
                }}
              />
            ) : null}
          </Stack>

          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <TabContext value={tabValue}>
              <TabList onChange={handleChange}>
                <Tab label="Roles" value="Roles" icon={awsIcons.roles} />
                <Tab label="K8 Roles" value="K8" icon={<SiKubernetes />} />
              </TabList>
            </TabContext>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            disabled={isSyncing}
            onClick={ondemandButton}
          >
            {isSyncing ? "Syncing" : "Sync"}
          </Button>
        </Box>
        <CustomTabPanel value={tabValue} index="Roles">
          <GCPGenericRoles latestTenant={latestTenant} projectId={projectId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index="K8">
          <K8Roles
            selectedTenantName={selectedTenantName}
            allapi={`/api/gcp/kubernetes-clusters?tenantName=${selectedTenantName}&projectId=whiteswan-vm-allocation`}
            rolesApi="/api/k8-resources/v1/roles/byClusterId"
            rolespoliciesApi="/api/k8-resources/v1/rolePolicies/byRoleUid"
            cloud="GCP"
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default GcpRoles;
