import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TimerForAWS from "../../../components/TimerForAWS";
import AzureRequestResourceAccessForm from "./AzureRequestResourceAccessForm";
import useNotificationsStore from "../../../../services/notificationStore";

const AccessCluster = ({
  publishedK8Clusters,
  setPublishedK8Clusters,
  user,
}) => {
  const { role, userId } = useUserStore((state) => state.user);
  const [selectedResource, setSelectedResource] = useState(null);
  const [
    onAzureRequestResourceAccessForm,
    setOnAzureRequestResourceAccessForm,
  ] = useState(false);

  const { userAzureNotifications } = useNotificationsStore();
  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);

  const handleClickRequestForm = (resource) => {
    setSelectedResource(resource);
    setOnAzureRequestResourceAccessForm(true);
  };

  const fetchResourceLevel = userAzureNotifications?.filter(
    (userAzureNotification) =>
      userAzureNotification.scopeType === "AZURE_KUBERNETES" &&
      userAzureNotification.status === "APPROVED",
  );

  const checkAccessTiming = (scope) => {
    return fetchResourceLevel.find((data) => data.scope === scope);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Type</TableCell>
              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedK8Clusters?.length > 0 ? (
              publishedK8Clusters.map((resource, index) => {
                const accessData = checkAccessTiming(resource?.azureId);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {resource.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {resource.regionName || resource.location || "-"}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {resource.type || "-"}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {role === "TENANT_USER" ? (
                      accessData ? (
                        <>
                          <TableCell>
                            <TimerForAWS
                              accessTimeOverAt={accessData.validTo}
                              resourceId={resource?.azureId}
                              selectedTime={accessData.expiryTimeAmount}
                              setTimedResourcesWithoutAccess={
                                setTimedResourcesWithoutAccess
                              }
                            />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickRequestForm(resource)}
                          >
                            Request Access
                          </Button>
                        </TableCell>
                      )
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No Clusters Available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedResource && onAzureRequestResourceAccessForm && (
        <AzureRequestResourceAccessForm
          open={onAzureRequestResourceAccessForm}
          onClose={() => {
            setOnAzureRequestResourceAccessForm(false);
            setSelectedResource(null);
          }}
          data={selectedResource}
          title={selectedResource?.name}
          user={user}
          from={"AZURE_KUBERNETES"}
        />
      )}
    </>
  );
};

export default AccessCluster;
