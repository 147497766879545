import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMaxStyles, useStyles } from "../../../../GenericMethods";
import moment from "moment";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const KubernetesResourcesDetails = ({
  resources,
  from,
  subscriptionId,
  azureId,
  k8ProviderType,
  tenantName,
  clusterPublished,
}) => {
  const classes = useMaxStyles();

  const [searchResourceDetailsQuery, setSearchResourceDetailsQuery] =
    useState("");
  const [selectedResourceUID, setSelectedResourceUID] = useState(null);

  const [resourcesList, setResourcesList] = useState([]);

  const handlePublishToggle = async (event, res) => {
    const published = event.target.checked;
    setResourcesList((prevData) =>
      prevData.map((resource) =>
        resource.uid === res?.uid ? { ...resource, published } : resource,
      ),
    );

    let requestData = {
      resourceId: res?.uid,
      wsTenantName: tenantName,
      flag: event.target.checked,
      type: from,
      cloudProviderType: k8ProviderType,
      clusterId: azureId,
      resourceAccountId: res.cloudResourceAccountId,
    };

    const response = await axios.patch(
      `${NGROK}/api/k8-resources/v1/publish`,
      requestData,
    );
  };

  const filteredAzureK8ResourcesDetails = resourcesList.filter((resource) =>
    resource.name
      .toLowerCase()
      .includes(searchResourceDetailsQuery.toLowerCase()),
  );
  useEffect(() => {
    setSelectedResourceUID(null);
    setResourcesList(resources);
  }, [resources]);

  return (
    <>
      <Box sx={{ width: "500px", minWidth: "400px", flex: "0 1 auto" }}>
        <TableContainer
          component={Paper}
          sx={{
            overflow: "auto",
            height: "100%",
          }}
          className={classes.root}
        >
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
                position: "sticky",
                top: 0,
                zIndex: 1,
              },
              "& td, & th": {
                borderBottom: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={7} align="left">
                  Resources
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resourcesList.length > 0 ? (
                <>
                  <TableRow
                    sx={{
                      position: "sticky",
                      top: "54px",
                      backgroundColor: "#f5f5f5",
                      zIndex: 1,
                    }}
                  >
                    <TableCell colSpan={7}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Resources"
                        value={searchResourceDetailsQuery}
                        onChange={(event) => {
                          setSearchResourceDetailsQuery(event.target.value);
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  {filteredAzureK8ResourcesDetails.map(
                    (resourceDetails, index) => (
                      <TableRow
                        key={index}
                        selected={
                          resourceDetails.uid === selectedResourceUID?.uid
                        }
                        onClick={() => {
                          setSelectedResourceUID(resourceDetails);
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {resourceDetails.name}
                              </Typography>
                            </Box>
                            <Box ml={2}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={!clusterPublished}
                                    checked={resourceDetails?.published}
                                    onChange={(e) => {
                                      handlePublishToggle(e, resourceDetails);
                                    }}
                                    name="publishSwitch"
                                  />
                                }
                                label="Publish"
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography align="center">
                      No Resource details Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedResourceUID ? (
        <>
          <Box
            sx={{
              border: "1px solid",
              width: "500px",
              minWidth: "400px",
              flex: "0 1 auto",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              Details of {selectedResourceUID?.name}
            </Typography>
            <List>
              {selectedResourceUID ? (
                <div>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Name</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{
                            fontSize: 18,
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {selectedResourceUID?.name || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>API Version</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.apiVersion || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>UID</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.uid || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Kind</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.kind || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Phase</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.phase || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Creation Timestamp</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {moment(
                            selectedResourceUID?.creationTimestamp,
                          ).format("YYYY-MM-DD HH:mm") || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Namespace</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.namespace || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Cluster ID</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{
                            fontSize: 18,
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {selectedResourceUID?.clusterId || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Cloud Provider</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.cloudProviderType || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Tenant Name</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.wsTenantName || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18 }}>
                          <strong>Resource Account ID</strong>
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedResourceUID?.resourceAccountId || "-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                </div>
              ) : (
                <Typography variant="body1">No details available</Typography>
              )}
            </List>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default KubernetesResourcesDetails;
