import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AWSSetup = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [arn, setArn] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [externalId, setExternalId] = useState("");
  const [configStatus, setConfigStatus] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [config, setConfig] = useState(null);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [unconfigSnack, setUnconfigSnack] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsSubmitted(false);
    checkIfEnabled(e.target.value, description);
    setIsDownloaded(false);
  };

  const handleDescriptionChange = (e) => {
    setIsSubmitted(false);
    setDescription(e.target.value);
    checkIfEnabled(name, e.target.value);
    setIsDownloaded(false);
  };

  const handleArnChange = (e) => {
    setIsSubmitted(false);
    setArn(e.target.value);
  };

  const checkIfEnabled = (name, description) => {
    setIsSubmitted(false);
    if (name && description) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  const downloadFile = async () => {
    setIsSubmitted(false);
    try {
      const response = await axios.get(`${NGROK}/api/aws-initial-setup`, {
        params: {
          name,
          description,
          arn,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(url);
      setIsDownloaded(true);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "aws-setup.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    await downloadFile();
  };

  const handleIconClick = async () => {
    if (isEnabled) {
      await handleSubmit(new Event("submit"));
    }
  };

  const handleUnconfigureSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(`${NGROK}/api/aws/unconfig-crossaccount?tenantName=${latestTenant}`)
      .then(() => {
        setConfig(null);
        setConfigStatus(false);
        setArn("");
        setExternalId("");
        setUnconfigSnack(true);
      });
    fetchAWSConfig();
  };

  const handleAdditionalSubmit = async (e) => {
    e.preventDefault();
    setConfig(null);
    setIsConfigLoading(true);
    await axios
      .post(`${NGROK}/api/aws/setup-crossaccount`, {
        tenantName: latestTenant,
        awsARN: arn,
        externalId: externalId,
      })
      .then((data) => {
        setConfig("Configured");
        setIsSubmitted(true);
        setConfigStatus(true);
        setIsConfigLoading(false);
        setIsEnabled(false);
      })
      .catch((err) => {
        setIsSubmitted(true);
        setConfigStatus(false);
        setIsConfigLoading(false);
        setIsEnabled(true);
      });
  };

  const fetchAWSConfig = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/aws/cross-account-config`,
        {
          params: {
            tenantName: latestTenant,
          },
        },
      );
      if (response.data) {
        setConfig(response?.data);
        setArn(response.data.crossAccountArn);
        setExternalId(response.data.crossAccountExternalId);
      }
    } catch (e) {
      setConfig(null);
    }
  };

  useEffect(() => {
    fetchAWSConfig();
  }, [latestTenant]);

  return (
    <Container maxWidth="md">
      <Snackbar
        open={unconfigSnack}
        autoHideDuration={6000}
        onClose={() => setUnconfigSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setUnconfigSnack(false)}
          severity={"success"}
          sx={{ width: "100%" }}
        >
          Unconfigured AWS successfully
        </Alert>
      </Snackbar>
      {config ? (
        <Alert sx={{ marginTop: 1 }} severity="success">
          {"AWS account configured successfully"}
        </Alert>
      ) : configStatus && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="success">
          {"AWS account configured successfully"}
        </Alert>
      ) : !configStatus && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {"Error configuring AWS account"}
        </Alert>
      ) : null}

      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          AWS Initial Setup
        </Typography>
        {!config ? (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
              />
            </Box>

            <Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: "grey.200",
                  flexGrow: 1,
                }}
              >
                <Typography variant="body1">aws-setup.json</Typography>
                <IconButton
                  color="primary"
                  disabled={!isEnabled}
                  onClick={handleIconClick}
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Box>
          </form>
        ) : null}

        {isDownloaded && isEnabled ? (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Details
            </Typography>
            <form onSubmit={handleAdditionalSubmit}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="ARN ID"
                  variant="outlined"
                  value={arn}
                  onChange={handleArnChange}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="External Id"
                  variant="outlined"
                  value={externalId}
                  onChange={(e) => setExternalId(e.target.value)}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isConfigLoading}
              >
                Submit Info
              </Button>
            </form>
          </Box>
        ) : null}

        {config ? (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Details
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="ARN ID"
                variant="outlined"
                value={arn}
                disabled
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="External Id"
                variant="outlined"
                value={externalId}
                disabled
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleUnconfigureSubmit}
              >
                Unconfigure AWS Account
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          mt: 10,
          p: 5,
          border: "1px solid ",
          borderColor: "grey.200",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h6">Setup Instructions</Typography>
        <Typography variant="body1" gutterBottom>
          Follow these steps to configure your AWS account:
        </Typography>
        <Typography variant="body2" component="ol">
          <Box component="li" mb={2}>
            <Typography variant="subtitle1">
              <strong>Provide Name and Description</strong>
            </Typography>
            <Typography variant="body2">
              Enter the Name and Description below. Once both fields are filled,
              the download option will be enabled.
            </Typography>
          </Box>

          <Box component="li" mb={2}>
            <Typography variant="subtitle1">
              <strong>Download aws-setup.json</strong>
            </Typography>
            <Typography variant="body2">
              Click the download icon to obtain the `aws-setup.json` file. This
              file will be used for the next step.
            </Typography>
          </Box>

          <Box component="li" mb={2}>
            <Typography variant="subtitle1">
              <strong>Configure AWS in CloudFormation</strong>
            </Typography>
            <Typography variant="body2">
              Log into your AWS Management Console and navigate to
              CloudFormation. Use the downloaded file to create a new
              CloudFormation stack.
            </Typography>
          </Box>

          <Box component="li" mb={2}>
            <Typography variant="subtitle1">
              <strong>Retrieve External ID and Role ARN</strong>
            </Typography>
            <Typography variant="body2">
              After the CloudFormation stack is successfully created, copy the
              External ID and Role ARN from the stack's output.
            </Typography>
          </Box>

          <Box component="li" mb={2}>
            <Typography variant="subtitle1">
              <strong>Submit the ARN and External ID</strong>
            </Typography>
            <Typography variant="body2">
              Return to this page and provide the ARN ID and External ID below.
              Click "Submit Info" to finalize the configuration.
            </Typography>
          </Box>

          <Box component="li">
            <Typography variant="subtitle1">
              <strong>Confirmation</strong>
            </Typography>
            <Typography variant="body2">
              Upon successful submission, you will receive a confirmation
              message.
            </Typography>
          </Box>
        </Typography>
      </Box>
    </Container>
  );
};

export default AWSSetup;
