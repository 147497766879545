import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ConnectionModal = ({
  open,
  onClose,
  resourceName,
  resourcePath,
  computer,
  users = [],
  handleConnectButton,
  filterdCnUsers,
  setSelectedTime,
  selectedTime,
  handleSubmit,
  prepareForSubmit,
}) => {
  const [mode, setMode] = useState("generic");
  const [step, setStep] = useState(2);
  const [stepAuthMode, setStepAuthMode] = useState(1);
  const [selectedCnUser, setSelectedCnUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [accessType, setAccessType] = useState("DYNAMIC");
  const [requestMessage, setRequestMessage] = useState("");

  const handleSearch = (e) => setSearchQuery(e.target.value);
  const handleUserSelect = (user) => setSelectedCnUser(user);
  const handleAccessTypeChange = (value) => setAccessType(value);
  const handleUserCheck = (user) => setCheckedUsers([user]);

  const resetValue = () => {
    setCheckedUsers([]);
    setMode("generic");
    setStep(2);
    setStepAuthMode(1);
    setSelectedCnUser(null);
    setSearchQuery("");
    setAccessType("DYNAMIC");
    setRequestMessage("");
  };

  useEffect(() => {
    resetValue();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onClose();
        } else {
          onClose();
        }
      }}
    >
      <DialogTitle>Select Connection Mode</DialogTitle>
      <DialogContent>
        <RadioGroup value={mode} onChange={(e) => setMode(e.target.value)} row>
          <FormControlLabel
            value="generic"
            control={<Radio />}
            label="JIT User"
          />
          <FormControlLabel
            value="authMode"
            control={<Radio />}
            label="Configured Credentials"
          />
          <FormControlLabel
            value="manualMode"
            control={<Radio />}
            label="Manual Credentials"
          />
        </RadioGroup>

        {mode === "generic" && (
          <Stack mt={2}>
            {step === 1 ? (
              <>
                <TextField
                  label="Search users"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <List
                  sx={{
                    maxHeight: "450px",
                    overflowY: "auto",
                    marginTop: "16px",
                  }}
                >
                  {filterdCnUsers.length === 0 ? (
                    <ListItem sx={{ justifyContent: "center", width: "100%" }}>
                      <Typography variant="h6">
                        <strong>No Users available</strong>
                      </Typography>
                    </ListItem>
                  ) : (
                    filterdCnUsers.map((user, index) => (
                      <ListItem key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCnUser?.cn === user?.cn}
                              onChange={() => handleUserSelect(user)}
                            />
                          }
                          label={user.cn}
                        />
                      </ListItem>
                    ))
                  )}
                </List>
              </>
            ) : (
              <Stack spacing={4}>
                <FormControl component="fieldset">
                  <Typography variant="h6">Access Type:</Typography>
                  <RadioGroup
                    value={accessType}
                    onChange={(e) => handleAccessTypeChange(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value="PERMANENT"
                      control={<Radio />}
                      label="Permanent"
                    />
                    <FormControlLabel
                      value="DYNAMIC"
                      control={<Radio />}
                      label="Timed"
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="h6">
                  {` Access request to ${resourceName}`}
                </Typography>
                <Typography variant="h6">
                  Computer Name: {computer?.dNSHostName}
                </Typography>
                <Stack spacing={2} mt={3}>
                  <Typography variant="h6">Access justification:</Typography>
                  <TextField
                    maxRows={5}
                    minRows={5}
                    inputProps={{ maxLength: 150 }}
                    multiline
                    value={requestMessage}
                    onChange={(e) => setRequestMessage(e.target.value)}
                  />
                </Stack>
                {accessType === "DYNAMIC" && (
                  <Stack spacing={2}>
                    <Typography variant="h6">Time:</Typography>
                    <TextField
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
                      value={selectedTime}
                      type="text"
                      fullWidth
                      size="small"
                      label="Enter time in minutes"
                      onChange={(e) => setSelectedTime(e.target.value)}
                    />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        )}

        {mode === "authMode" ? (
          stepAuthMode === 1 ? (
            <List>
              {users.map((user) => (
                <ListItem key={user} button>
                  <ListItemIcon>
                    <Checkbox
                      checked={checkedUsers.includes(user)}
                      onChange={() => handleUserCheck(user)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={user.credentialUsername} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Stack spacing={4} mt={2}>
              <FormControl component="fieldset">
                <Typography variant="h6">Access Type:</Typography>
                <RadioGroup
                  value={accessType}
                  onChange={(e) => handleAccessTypeChange(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="PERMANENT"
                    control={<Radio />}
                    label="Permanent"
                  />
                  <FormControlLabel
                    value="DYNAMIC"
                    control={<Radio />}
                    label="Timed"
                  />
                </RadioGroup>
              </FormControl>
              <Typography variant="h6">
                {` Access request to ${resourceName}`}
              </Typography>
              <Typography variant="h6">
                Connect via: {checkedUsers[0]?.credentialUsername}
              </Typography>
              <Stack spacing={2} mt={3}>
                <Typography variant="h6">Access justification:</Typography>
                <TextField
                  maxRows={5}
                  minRows={5}
                  inputProps={{ maxLength: 150 }}
                  multiline
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                />
              </Stack>
              {accessType === "DYNAMIC" && (
                <Stack spacing={2}>
                  <Typography variant="h6">Time:</Typography>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
                    value={selectedTime}
                    type="text"
                    fullWidth
                    size="small"
                    label="Enter time in minutes"
                    onChange={(e) => setSelectedTime(e.target.value)}
                  />
                </Stack>
              )}
            </Stack>
          )
        ) : null}

        {mode === "manualMode" && (
          <>
            <Stack spacing={4} mt={2}>
              <FormControl component="fieldset">
                <Typography variant="h6">Access Type:</Typography>
                <RadioGroup
                  value={accessType}
                  onChange={(e) => handleAccessTypeChange(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="PERMANENT"
                    control={<Radio />}
                    label="Permanent"
                  />
                  <FormControlLabel
                    value="DYNAMIC"
                    control={<Radio />}
                    label="Timed"
                  />
                </RadioGroup>
              </FormControl>
              <Typography variant="h6">
                {` Access request to ${resourceName}`}
              </Typography>
              <Typography variant="h6">
                Connect via: Manual Credential
              </Typography>
              <Stack spacing={2} mt={3}>
                <Typography variant="h6">Access justification:</Typography>
                <TextField
                  maxRows={5}
                  minRows={5}
                  inputProps={{ maxLength: 150 }}
                  multiline
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                />
              </Stack>
              {accessType === "DYNAMIC" && (
                <Stack spacing={2}>
                  <Typography variant="h6">Time:</Typography>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
                    value={selectedTime}
                    type="text"
                    fullWidth
                    size="small"
                    label="Enter time in minutes"
                    onChange={(e) => setSelectedTime(e.target.value)}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {mode === "generic" ? (
          step === 1 ? (
            <Button
              onClick={() => setStep(2)}
              color="primary"
              disabled={!selectedCnUser}
            >
              Next
            </Button>
          ) : (
            <>
              <Button onClick={() => setStep(1)} color="primary">
                Back
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                disabled={accessType === "DYNAMIC" && !selectedTime}
                color="primary"
              >
                Submit
              </Button>
            </>
          )
        ) : mode === "authMode" ? (
          <>
            {stepAuthMode === 1 ? (
              <Button
                onClick={() => setStepAuthMode(2)}
                color="primary"
                disabled={checkedUsers.length === 0}
              >
                Next
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setStepAuthMode(1);
                    setCheckedUsers([]);
                  }}
                  color="primary"
                >
                  Back
                </Button>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    prepareForSubmit(
                      "existingCredentials",
                      checkedUsers[0]?.credentialLabel,
                      checkedUsers[0]?.credentialUsername,
                    );
                    onClose();
                  }}
                  disabled={accessType === "DYNAMIC" && !selectedTime}
                  color="primary"
                >
                  Submit
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => {
                prepareForSubmit("manualCredentials", null, null);
                onClose();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConnectionModal;
