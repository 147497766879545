import create from "zustand";
import axios from "axios";
import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";

const useUserStore = create((set) => ({
  user: {},
  loading: false,
  hasErrors: false,

  azureRedirectURI: "",

  tenantName: "",
  apiKey: "",
  userName: "",
  isCachingEnabled: "",
  activeComputer: null,
  computerId: null,
  defaultTenant: undefined,
  selectedProfile: undefined,
  webUrlDetails: undefined,

  lastSession: {
    email: null,
    latestTenant: null,
    latestComputerId: null,
    latestUserId: null,
    resourceId: null,
    latestPublisherId: null,
    latestAppId: null,
    latestFolderId: null,
    latestGroupId: null,
    latestTenantMode: null,
    latestUsername: null,
  },

  setAzureRedirectURI: (url) => {
    set((state) => ({
      user: {
        ...state.user,
        azureRedirectURI: url,
      },
    }));
  },

  // later hange
  setUserRole: (role) => {
    set((state) => ({
      user: {
        ...state.user,
        role: role,
      },
    }));
  },

  setApiKey: (key) => {
    set(() => ({ apiKey: key }));
  },
  setTenantName: (name) => {
    set(() => ({ tenantName: name }));
  },
  setDefaultTenant: (tenant) => {
    set(() => ({ defaultTenant: { ...tenant } }));
  },
  setActiveComputer: (computer) => {
    set(() => ({ activeComputer: { ...computer } }));
  },
  setLastSession: (session) => {
    set(() => ({ lastSession: { ...session } }));
  },
  setSelectedProfile: (profile) => {
    set(() => ({ selectedProfile: { ...profile } }));
  },
  setUserName: (name) => {
    set(() => ({ userName: name }));
  },
  setComputerId: (id) => {
    set(() => ({ computerId: id }));
  },
  setisCachingEnabled: (bullean) => {
    set(() => ({ isCachingEnabled: bullean }));
  },
  setWebURLDetails: (webURl) => {
    set(() => ({ webUrlDetails: { ...webURl } }));
  },

  logOut: async (email) => {
    try {
      axios.get(`${NGROK}/api/logout`);
    } catch (error) {
      set(() => ({ hasErrors: true }));
    }
  },

  sessionMinutesLeft: 0,
  setSessionMinutesLeft: (minutes) =>
    set(() => ({ sessionMinutesLeft: minutes })),

  getUserData: async (email) => {
    set(() => ({ loading: true }));
    try {
      const axiosInstance = axios.create();
      let emailId = email || myLocalStorage.getItem("email");

      const response = await axiosInstance.get(
        `${NGROK}/api/check-user?email=${emailId}`,
      );
      set((state) => ({ user: (state.data = response.data), loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
}));
export const fetchUserDataStore = () => useUserStore.getState().getUserData;

export default useUserStore;
