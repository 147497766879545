import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  Switch,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthPublishPopover from "./AuthPublishPopover";
import { Edit, Delete } from "@mui/icons-material";
import PublishPopover from "../../Endpoints/components/PublishPopover";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import UrlTableCell from "./UrlTableCell";
import moment from "moment/moment";
import EditAuth from "./EditAuth";
import useUserStore from "../../../../services/userStore";
import UserConfiguredURLs from "./UserConfiguredURLs";

const ConfiguredURLs = ({ selectedTenantName, searchTerm }) => {
  const { role, email, userId, userRoleId, userRole } = useUserStore(
    (state) => state.user,
  );
  const [userRoleList, setUserRoleList] = useState([]);
  const [webUrls, setWebUrls] = useState([]);
  const [deleteSnack, setDeleteSnack] = useState(false);
  const [updateSnack, setUpdateSnack] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const getAllUserRoles = async (tenantName) => {
    if (tenantName) {
      const response = await axios.get(
        `${NGROK}/api/${tenantName}/users-role-details`,
      );
      setUserRoleList(response?.data);
    }
  };
  const updateManageWebUrl = async (id, rolesList) => {
    const filteredRolesList = [
      ...new Set(rolesList.filter((role) => role !== undefined)),
    ];
    const response = await axios.put(
      `${NGROK}/api/websites/publish?tenantName=${selectedTenantName}&webID=${id}&rolesList=${filteredRolesList.join(",")}`,
    );
  };
  const getWebURLs = async (tenantName) => {
    const response = await axios.get(
      `${NGROK}/api/websites/getAllAuth?tenantName=${tenantName}&email=${email}`,
    );
    if (response?.data) {
      setWebUrls(response?.data);
    }
  };

  const handleDelete = async (id) => {
    const response = await axios.delete(
      `${NGROK}/api/websites/deleteAuth/${id}`,
    );
    if (response) {
      setDeleteSnack(true);
    }
  };
  const handleUpdateUrlPopup = () => {
    setUpdateSnack(true);
  };

  const showServerResponse = (value) => {
    setErrorServer(value);
  };

  const modifyTheResponse = (item) => {
    const modifiedResponse = {
      ...item,
      isManageComputer: item.isManaged,
      isServer: true,
    };
    return modifiedResponse;
  };

  const toggleRecording = async (item) => {
    const response = await axios.put(
      `${NGROK}/api/websites/updateRecording?tenantName=${selectedTenantName}&webId=${item?.id}`,
    );
  };

  const sortedWebUrls = webUrls
    ?.filter((url) => url.url.toLowerCase().includes(searchTerm.toLowerCase()))
    ?.sort((a, b) => a.id - b.id);

  useEffect(() => {
    getAllUserRoles(selectedTenantName);
    getWebURLs(selectedTenantName);
    const interval = setInterval(() => {
      getWebURLs(selectedTenantName);
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedTenantName]);

  return (
    <>
      <Snackbar
        open={updateSnack}
        autoHideDuration={6000}
        onClose={() => setUpdateSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setUpdateSnack(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Updated Web Url
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteSnack}
        autoHideDuration={6000}
        onClose={() => setDeleteSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setDeleteSnack(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Removed the Web URL
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorServer}
        autoHideDuration={6000}
        onClose={() => showServerResponse(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => showServerResponse(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          WSWebApp not running... Make sure that the zip is downloaded and
          WSWebApp is running
        </Alert>
      </Snackbar>

      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        {role !== "TENANT_USER" ? (
          <Table
            sx={{
              height: "fit-content",
              maxWidth: "100%",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">URL</TableCell>
                <TableCell align="center">Management</TableCell>
                <TableCell align="center">Recording</TableCell>
                <TableCell align="center">Access</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedWebUrls.length > 0 ? (
                sortedWebUrls.map((item) => (
                  <TableRow key={item.id}>
                    <UrlTableCell url={item.url} item={item} />

                    <TableCell align="center">
                      <PublishPopover
                        device={modifyTheResponse(item)}
                        updateManageComputer={updateManageWebUrl}
                        userRoleList={userRoleList}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Switch
                          checked={item?.recordingenabled}
                          onChange={() => toggleRecording(item)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <AuthPublishPopover
                        auth={item}
                        selectedTenantName={selectedTenantName}
                        showServerResponse={showServerResponse}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                      >
                        <EditAuth
                          website={item}
                          selectedTenant={selectedTenantName}
                          handleUpdateUrlPopup={handleUpdateUrlPopup}
                        />

                        <Tooltip title="Delete">
                          <Button
                            color="error"
                            variant="outlined"
                            startIcon={<Delete />}
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colspan={7} align="center">
                    No WebUrls found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          <UserConfiguredURLs
            webUrls={sortedWebUrls}
            tenantName={selectedTenantName}
            showServerResponse={showServerResponse}
          />
        )}
      </TableContainer>
    </>
  );
};

export default ConfiguredURLs;
