import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import useUserStore from "../../../../services/userStore";

const WebURLCrumpUsers = ({ path }) => {
  const location = useLocation();
  const webUrlDetails = useUserStore((state) => state.webUrlDetails);

  const toCamelCase = (str) => {
    return str
      .split(/(?=[A-Z])/)
      .join(" ")
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(/\s+/g, "");
  };
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ fontSize: "22px", mt: -5 }}
      >
        <Link style={{ color: "black" }} to={"/webUrls"}>
          Web URLs
        </Link>
        {location.pathname === "/webUrls/webUrldetails" ? (
          <Typography fontSize={22} color="text.primary">
            Sessions
          </Typography>
        ) : null}
        {location.pathname === "/webUrls/webUrldetails" && webUrlDetails ? (
          <Typography fontSize={22} color="text.primary">
            {webUrlDetails?.url}
          </Typography>
        ) : null}
      </Breadcrumbs>
    </>
  );
};

export default WebURLCrumpUsers;
