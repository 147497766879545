import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const EventDetailsJson = ({ data }) => {
  if (!data) return <Typography>No data available</Typography>;

  const parsedData = Object.keys(data).reduce((acc, key) => {
    try {
      acc[key] = JSON.parse(data[key]);
    } catch {
      acc[key] = data[key];
    }
    return acc;
  }, {});

  const generalInfo = { ...parsedData };
  delete generalInfo.eventJson;
  delete generalInfo.sigma_results;

  const eventDetails = parsedData.eventJson || {};
  const securityFindings = parsedData.sigma_results || [];

  const formatKey = (key) =>
    key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(/\s+/g, " ");

  const RenderJson = ({ data }) => {
    if (typeof data === "object" && !Array.isArray(data)) {
      return (
        <List dense sx={{ padding: 0, margin: 0 }}>
          {Object.entries(data).map(([key, value]) => (
            <ListItem key={key} sx={{ padding: "2px 8px" }}>
              <ListItemText
                primary={
                  <Typography sx={{ margin: 0, fontSize: "15px" }}>
                    <strong>
                      {formatKey(key) === "ComputerUserEmail"
                        ? "User"
                        : formatKey(key)}
                      :{" "}
                    </strong>
                    {typeof value === "object" ? (
                      <RenderJson data={value} />
                    ) : (
                      <span style={{ fontSize: "15px" }}>{String(value)}</span>
                    )}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      );
    }

    if (Array.isArray(data)) {
      return (
        <List dense sx={{ padding: 0, margin: 0 }}>
          {data.map((item, index) => (
            <ListItem key={index} sx={{ padding: "2px 8px" }}>
              <ListItemText
                primary={
                  typeof item === "object" ? (
                    <RenderJson data={item} />
                  ) : (
                    <Typography sx={{ margin: 0, fontSize: "15px" }}>
                      {item}
                    </Typography>
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      );
    }

    return (
      <Typography sx={{ margin: 0, fontSize: "16px" }}>
        {String(data)}
      </Typography>
    );
  };

  const renderSection = (title, content, bgColor) => (
    <Box
      sx={{
        flex: 1,
        minWidth: 300,
        padding: "8px",
        borderRadius: "5px",
        bgcolor: bgColor || "background.paper",
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginBottom: "4px", fontSize: "18px" }}
      >
        {formatKey(title)}
      </Typography>
      <RenderJson data={content} />
    </Box>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: 2,
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <Grid item xs={12} sm={3.8} md={3.8} sx={{ display: "flex" }}>
        {renderSection("General Information", generalInfo)}
      </Grid>

      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Divider orientation="vertical" flexItem />
      </Grid>

      <Grid item xs={12} sm={3.8} md={3.8} sx={{ display: "flex" }}>
        {renderSection("Event Details", eventDetails)}
      </Grid>

      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Divider orientation="vertical" flexItem />
      </Grid>

      <Grid item xs={12} sm={3.8} md={3.8} sx={{ display: "flex" }}>
        {renderSection("Security Findings", securityFindings)}
      </Grid>
    </Grid>
  );
};

export default EventDetailsJson;
