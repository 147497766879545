import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RequestMessageDialog from "../../../components/RequestMessageDialog";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import moment from "moment";

const RequestWebURLTableBody = ({ adminNotifications }) => {
  const [loadingRequests, setLoadingRequests] = useState(adminNotifications);
  const userData = useUserStore((state) => state.user);

  let userRole = userData.role;
  const tenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleRequest = async (id, status, notification) => {
    try {
      console.log(loadingRequests);
      setLoadingRequests((prevData) =>
        prevData.map((notificationItem) =>
          notificationItem.id === id
            ? { ...notificationItem, requestStatus: status }
            : notificationItem,
        ),
      );

      await axios.put(
        `${NGROK}/api/websites/weburl-request-access?tenantName=${tenant}`,
        {
          accessId: id,
          requestStatus: status,
          accessType: notification.accessType,
        },
      );
    } catch (error) {
      console.error(error);
      setLoadingRequests((prevData) =>
        prevData.map((notificationItem) =>
          notificationItem.id === id
            ? { ...notificationItem, requestStatus: "PENDING" }
            : notificationItem,
        ),
      );
    }
  };

  useEffect(() => {
    setLoadingRequests(adminNotifications);
  }, [adminNotifications]);

  return (
    <>
      <TableBody>
        {loadingRequests.length ? (
          loadingRequests.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {moment(notification.createdAt).format("YYYY/MM/DD/HH:mm")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="left"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.email || "-"}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.accessUrl}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  maxWidth: "350px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.accessCredential || "-"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
              >
                <RequestMessageDialog
                  messageText={notification.requestMessage}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Stack spacing={2}>
                  <Typography
                    fontWeight={500}
                    fontSize={16}
                    variant="subtitle2"
                  >
                    DYNAMIC
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={16}
                    variant="subtitle2"
                  >
                    {notification.selectedTime} min
                  </Typography>
                </Stack>
              </TableCell>
              {notification.requestStatus === "PENDING" ? (
                userRole !== "TENANT_USER" ? (
                  <>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                      }}
                      align="center"
                    >
                      <Button
                        onClick={() => {
                          handleRequest(
                            notification.id,
                            "APPROVED",
                            notification,
                          );
                        }}
                        variant="outlined"
                      >
                        Approve
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                      }}
                      align="center"
                    >
                      <Button
                        onClick={() => {
                          handleRequest(
                            notification.id,
                            "DECLINE",
                            notification,
                          );
                        }}
                        color="error"
                        variant="outlined"
                      >
                        Decline
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    colSpan={2}
                    sx={{
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    <Button color="success" variant="outlined">
                      Pending
                    </Button>
                  </TableCell>
                )
              ) : notification.requestStatus === "APPROVED" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="success" variant="outlined">
                    APPROVED
                  </Button>
                </TableCell>
              ) : notification.requestStatus === "DECLINE" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    DECLINED
                  </Button>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    EXPIRED
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={10}
              sx={{
                fontSize: "16px",
              }}
              align="center"
            >
              <Typography fontWeight={500} fontSize={16} variant="subtitle2">
                No WebURL Request found
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

export default RequestWebURLTableBody;
