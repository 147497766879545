import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandableUrlDetailsTableRow from "./ExpandableUrlDetailsTableRow";

const WebURLRecording = ({ urlDetails, tenantName }) => {
  const [urlDetailsData, setUrlDetailsData] = useState([]);
  const fetchAllUrlDetails = async (urlDetails, tenantName) => {
    const response = await axios.get(
      `${NGROK}/api/websites/${tenantName}/list-all-files-in-zips?webId=${urlDetails?.id}`,
    );
    if (response?.data) {
      setUrlDetailsData(response?.data);
    }
  };

  useEffect(() => {
    fetchAllUrlDetails(urlDetails, tenantName);
  }, [urlDetails, tenantName]);

  return (
    <>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            maxWidth: "100%",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "16px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Credential</TableCell>
              <TableCell align="center">Access IP</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Duration</TableCell>
              <TableCell align="center">Videos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {urlDetailsData.length > 0 ? (
              urlDetailsData.map((row, index) => (
                <ExpandableUrlDetailsTableRow key={index} row={row} />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  <Typography variant="h6">
                    No Recording session found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default WebURLRecording;
